import styled from '@emotion/styled';
import { Playlist } from '../../../../../shared/types/playlist';
import { mainColor, subTextColor, textColor } from '../../../../constants/colors';
import { ellipsis } from '../../../../utils/mixins';
import { DropdownMenu, DropdownItem } from '../../../_shared/components/DropdownMenu';
import { SlideUnderlineLabel } from '../../../_shared/components/Labels';
import { LoadingPane } from '../../../_shared/components/LoadingPane';
import { useDeletePlaylist, useRemoveMusicFromPlaylist } from '../../hooks';
import { MusicItem } from './MusicItem';
import { Tag } from './Tag';

export type PlaylistMetaPaneProps = {
  playlist: Playlist;
  playlistIndex: number;
  isEditingMusic: boolean;
  onChangePlaylistIndex: (playlistIndex: number) => void;
  onClickEditButton: () => void;
  className?: string;
};

const Container = styled.div`
  position: relative;
  padding: 12px 0;
  width: 100%;
  min-height: 100%;
  background-color: ${mainColor};
  box-sizing: border-box;
`;

const Title = styled.p`
  ${ellipsis()}
  margin: 0;
  padding: 0 8px;
  color: ${textColor};
  font-size: 18px;
  font-weight: 600;
`;

const TypeLabel = styled.p`
  margin: 8px 0 0;
  padding: 0 8px;
  color: ${textColor};
  font-size: 14px;
  font-weight: 500;
`;

const ArtistSection = styled.div`
  margin: 6px 0 0;
  min-height: 48px;
`;

const Artists = styled.div`
  margin: 0 0 0 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin-right: 8px;
    margin-bottom: 6px;
  }
`;

const Artist = styled(SlideUnderlineLabel)`
  color: ${subTextColor};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.4px;
`;

const Tags = styled.div`
  margin: 8px 0 0 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin-right: 14px;
    margin-bottom: 6px;
  }
`;

const SearchedMeta = styled.div`
  ${ArtistSection} + ${Tags} {
    margin-top: 0;
  }
`;

const Musics = styled.div`
  margin: 20px 0 0;
  width: 100%;
`;

const Menu = styled(DropdownMenu)`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const ArtistText = ({ artist, ...props }: { artist: string }) => {
  return <Artist {...props}>{artist}</Artist>;
};

export const PlaylistMetaPane = ({ playlist, playlistIndex, isEditingMusic, onChangePlaylistIndex, onClickEditButton, ...props }: PlaylistMetaPaneProps) => {
  const { isDeleting, onDelete } = useDeletePlaylist({ playlist });
  const { isRemoving, onRemove } = useRemoveMusicFromPlaylist({ playlist });

  if (playlist.type === 'single') return null;

  return (
    <Container {...props}>
      <Title>{playlist.title}</Title>
      <TypeLabel>タイプ: {playlist.type}</TypeLabel>
      {playlist.type === 'searched' ? (
        <SearchedMeta>
          {playlist.artists.length === 0 && playlist.units.length === 0 ? null : (
            <ArtistSection>
              {playlist.units.length === 0 ? null : (
                <Artists>
                  {playlist.units.map((unit) => (
                    <ArtistText key={unit} artist={unit} />
                  ))}
                </Artists>
              )}
              {playlist.artists.length === 0 ? null : (
                <Artists>
                  {playlist.artists.map((artist) => (
                    <ArtistText key={artist} artist={artist} />
                  ))}
                </Artists>
              )}
            </ArtistSection>
          )}
          <Tags>
            {playlist.tags.map((tag) => (
              <Tag key={tag} tag={tag} />
            ))}
          </Tags>
        </SearchedMeta>
      ) : null}
      <Musics>
        {playlist.musics.map(({ id, title, thumbnailUrl }, index) => (
          <MusicItem
            key={`${id}-${index}`}
            id={id}
            title={title}
            thumbnailUrl={thumbnailUrl}
            currentIndex={playlistIndex}
            index={index}
            onSelect={onChangePlaylistIndex}
            onRemove={onRemove}
          />
        ))}
      </Musics>
      <Menu pos="bottomRight">
        <DropdownItem text="編集" disabled={isEditingMusic} onClick={onClickEditButton} />
        <DropdownItem text="削除" onClick={onDelete} />
      </Menu>
      {isRemoving || isDeleting ? <LoadingPane /> : null}
    </Container>
  );
};
