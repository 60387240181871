import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export type ThreeDotsLoadingProps = {
  speed?: number;
  className?: string;
};

const flashing = keyframes`
  0% {
    opacity: 0.2;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;

const Container = styled.div<{ speed: number }>`
  color: #333;

  & > span {
    display: inline-block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background-color: currentColor;
    animation: ${flashing} ${({ speed }) => speed * 3}s infinite linear;

    &:not(:first-child) {
      margin-left: 0.5em;
    }

    &:nth-child(2) {
      animation-delay: ${({ speed }) => speed}s;
      opacity: 0;
    }

    &:nth-child(3) {
      animation-delay: ${({ speed }) => speed * 2}s;
      opacity: 0;
    }
  }
`;

export const ThreeDotsLoading = ({ speed, ...props }: ThreeDotsLoadingProps) => {
  return (
    <Container {...props} speed={(speed ?? 4) / 10}>
      <span />
      <span />
      <span />
    </Container>
  );
};
