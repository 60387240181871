import { memo, ReactNode, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

export type DropzoneInputProps = {
  accept: string[];
  maxFilesCount?: number;
  onDropAccepted: (files: File[]) => void;
  onDropRejected: () => void;
  onChangeDragActive?: (active: boolean) => void;
  children?: ReactNode;
  className?: string;
};

export const DropzoneInput = memo<DropzoneInputProps>(({ accept, maxFilesCount, onDropAccepted, onDropRejected, onChangeDragActive, children, ...props }) => {
  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles: maxFilesCount ?? 1,
    onDropAccepted,
    onDropRejected,
  });
  useEffect(() => {
    if (onChangeDragActive) onChangeDragActive(isDragActive);
  }, [isDragActive, onChangeDragActive]);

  return (
    <div {...props} {...getRootProps()}>
      {children}
      <input {...getInputProps()} />
    </div>
  );
});
