import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useCallback, useRef } from 'react';
import { accentColor, playerControlColor } from '../../../../constants/colors';
import { smartphone } from '../../../../utils/mediaQueries';
import { BasicButton } from '../../../_shared/components/Buttons';
import { IconVolumeUp, IconVolumeDown, IconVolumeMute, IconVolumeOff } from '../../../_shared/components/Icons';
import { useSlider } from '../../../_shared/hooks';

export type VolumeControllerProps = {
  volume: number;
  isMuted: boolean;
  disabled?: boolean;
  onChangeVolume: (volume: number) => void;
  onChangeMute: (isMuted: boolean) => void;
  className?: string;
};

const sliderWidth = 4;

const Button = styled(BasicButton)`
  width: 100%;
  height: 100%;
  color: ${playerControlColor};
`;

const BarWrapper = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  padding: 0 0 0;
  width: 28px;
  height: 66px;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 0);
  transition: opacity 0.3s ease;
`;

const VolumeBar = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: ${sliderWidth}px;
  height: 62px;
  transform: translate(-50%, 0);
`;

const Background = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${sliderWidth}px;
  height: calc(100% - 12px);
  background-color: #adadad;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease;
`;

const Gauge = styled.div<{ value: number }>`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  height: calc(${({ value }) => value} * 100%);
  background-color: ${accentColor};
  transform: translate(-50%, 0);
  transition: width 0.2s ease;
`;

const Handle = styled.div`
  position: absolute;
  top: 0;
  right: 50%;
  width: 0;
  height: 0;
  background-color: ${accentColor};
  border-radius: 50%;
  transform: translate(50%, -50%);
  opacity: 0;
  transition: width height opacity 0.4s ease;
`;

const HoverHandler = styled.div<{ isSliding: boolean }>`
  position: absolute;
  top: 0;
  left: 50%;
  width: 28px;
  height: 100%;
  transform: translate(-50%, 0);
  cursor: pointer;
  background-color: rgba(214, 197, 255, 0.5);

  &:hover {
    ${Background} {
      width: calc(${sliderWidth}px + 2px);
    }

    ${Handle} {
      width: 13px;
      height: 13px;
      opacity: 1;
    }
  }

  ${({ isSliding }) =>
    isSliding
      ? css`
          cursor: pointer;

          & ${Background} {
            width: calc(${sliderWidth}px + 2px);

            & > ${Gauge} {
              & > ${Handle} {
                width: 15px;
                height: 15px;
                opacity: 1;
              }
            }
          }
        `
      : ''}
`;

const Container = styled.div<{ isSliding: boolean; disabled: boolean }>`
  position: relative;

  ${({ disabled }) =>
    disabled
      ? ''
      : css`
          &:hover {
            ${BarWrapper} {
              opacity: 1;
              visibility: visible;
            }
          }

          ${smartphone`
            &:hover {
              ${BarWrapper} {
                opacity: 0;
                visibility: hidden;
              }
            }
          `}
        `}

  ${({ isSliding }) =>
    isSliding
      ? css`
          ${BarWrapper} {
            opacity: 1;
            visibility: visible;
          }

          ${smartphone`
            ${BarWrapper} {
              opacity: 0;
              visibility: hidden;
            }
          `}
        `
      : ''}
`;

export const VolumeController = ({ volume, isMuted, disabled, onChangeVolume, onChangeMute, ...props }: VolumeControllerProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const handleClick = useCallback(() => onChangeMute(!isMuted), [isMuted, onChangeMute]);
  const { isSliding } = useSlider(ref, {
    initialValue: volume,
    direction: 'vertical',
    reverse: true,
    onChange: onChangeVolume,
  });

  return (
    <Container isSliding={isSliding} disabled={disabled ?? false} {...props}>
      <Button disabled={disabled} onClick={handleClick}>
        {isMuted ? <IconVolumeOff /> : volume <= 0.2 ? <IconVolumeMute /> : volume <= 0.6 ? <IconVolumeDown /> : <IconVolumeUp />}
      </Button>
      <BarWrapper>
        <VolumeBar ref={ref}>
          <HoverHandler isSliding={isSliding}>
            <Background>
              <Gauge value={isMuted ? 0 : volume}>
                <Handle />
              </Gauge>
            </Background>
          </HoverHandler>
        </VolumeBar>
      </BarWrapper>
    </Container>
  );
};
