import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode, useMemo, useRef } from 'react';
import { useMeasure } from '../../../hooks';

export type MarqueeLabelProps = {
  marqueeGap?: number;
  marqueeDeplay?: number;
  marqueeSpeed?: number;
  isPausedMarquee?: boolean;
  children: ReactNode;
  className?: string;
};

function calcAnimationDuration(containerWidth: number, childrenWidth: number, marqueeSpeed: number): number {
  return marqueeSpeed * (childrenWidth / containerWidth);
}

const createMarqueeMoveKeyframes = (childrenWidth: number, space: number) => keyframes`
  0% { transform: translateX(0px) }
  100% { transform: translateX(-${childrenWidth + space}px) }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

const HiddenWrapper = styled.div`
  position: absolute;
  visibility: hidden;
`;

const MarqueeWrapper = styled.div<{
  needMarquee: boolean;
  isPausedMarquee: boolean;
  containerWidth: number;
  childrenWidth: number;
  marqueeDelay: number;
  marqueeGap: number;
  marqueeSpeed: number;
}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${({ needMarquee, containerWidth, childrenWidth, marqueeDelay, marqueeGap, marqueeSpeed }) =>
    needMarquee
      ? css`
          animation: ${calcAnimationDuration(containerWidth, childrenWidth, marqueeSpeed)}s linear ${createMarqueeMoveKeyframes(childrenWidth, marqueeGap)}
            infinite;
          animation-delay: ${marqueeDelay}s;
        `
      : ''}

  animation-play-state: ${({ isPausedMarquee }) => (isPausedMarquee ? 'paused' : 'running')};
`;

const Spacer = styled.div<{ space: number }>`
  width: ${({ space }) => space}px;
`;

export const MarqueeLabel = ({ marqueeGap, marqueeDeplay, marqueeSpeed, isPausedMarquee, children, ...props }: MarqueeLabelProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);
  const { width } = useMeasure<HTMLDivElement>(ref);
  const { width: childrenWidth } = useMeasure<HTMLDivElement>(childrenRef);
  const needMarquee = useMemo(() => width < childrenWidth - 1, [width, childrenWidth]);

  return (
    <Container ref={ref} {...props}>
      <HiddenWrapper ref={childrenRef}>{children}</HiddenWrapper>
      <MarqueeWrapper
        containerWidth={width}
        childrenWidth={childrenWidth}
        marqueeGap={marqueeGap ?? 24}
        marqueeDelay={marqueeDeplay ?? 3}
        marqueeSpeed={marqueeSpeed ?? 8}
        needMarquee={needMarquee}
        isPausedMarquee={isPausedMarquee ?? false}
      >
        {children}
        {needMarquee ? (
          <>
            <Spacer space={marqueeGap ?? 24} />
            {children}
            <Spacer space={marqueeGap ?? 24} />
          </>
        ) : null}
      </MarqueeWrapper>
    </Container>
  );
};
