import styled from '@emotion/styled';
import { memo } from 'react';
import { playerControlColor } from '../../../../../constants/colors';
import { BasicButton } from '../../../../_shared/components/Buttons';
import { IconSkipNext } from '../../../../_shared/components/Icons';

export type SkipNextButtonProps = {
  disabled?: boolean;
  onClick: () => void;
  className?: string;
};

const Button = styled(BasicButton)`
  color: ${playerControlColor};
`;

export const SkipNextButton = memo<SkipNextButtonProps>(({ disabled, onClick, ...props }) => {
  return (
    <Button disabled={disabled} onClick={onClick} {...props}>
      <IconSkipNext />
    </Button>
  );
});
