import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode, useCallback, useState } from 'react';
import { mainColor, textColor } from '../../../../constants/colors';
import { basicBoxShadow } from '../../../../utils/mixins';
import { IconMoreHorizontal } from '../Icons';

export type DropdownMenuProps = {
  pos?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';
  children?: ReactNode;
  className?: string;
};

const appearAnimation = keyframes`
  0% {
    transform: scaleY(0);
    opacity: 0;
  }

  100% {
    transform: scaleY(1);
    opacity: 1;
  }
`;

const MoreIcon = styled(IconMoreHorizontal)`
  width: 100%;
  color: ${textColor};
  fill: currentColor;
`;

const MenuWrapper = styled.div<{ pos: Required<DropdownMenuProps>['pos'] }>`
  ${basicBoxShadow}

  position: absolute;
  background-color: ${mainColor};
  border-radius: 2px;
  animation: 0.3s ease ${appearAnimation} forwards;

  ${({ pos }) => {
    switch (pos) {
      case 'bottomLeft': {
        return css`
          top: calc(100% - 4px);
          left: 0;
          transform-origin: top center;
        `;
      }
      case 'bottomRight': {
        return css`
          top: calc(100% - 4px);
          right: 0;
          transform-origin: top center;
        `;
      }
      case 'topLeft': {
        return css`
          bottom: calc(100% - 4px);
          left: 0;
          transform-origin: bottom center;
        `;
      }
      case 'topRight': {
        return css`
          bottom: calc(100% - 4px);
          right: 0;
          transform-origin: bottom center;
        `;
      }
    }
  }}
`;

const Container = styled.div`
  position: relative;
  width: 32px;
`;

export const DropdownMenu = ({ pos = 'bottomLeft', children, ...props }: DropdownMenuProps) => {
  const [isHidden, setIsHidden] = useState(true);
  const handleMouseEnter = useCallback(() => setIsHidden(false), []);
  const handleMouseLeave = useCallback(() => setIsHidden(true), []);

  return (
    <Container {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <MoreIcon />
      {isHidden ? null : <MenuWrapper pos={pos}>{children}</MenuWrapper>}
    </Container>
  );
};
