import { useSWRConfig } from 'swr';
import { addMusicToPlaylistPath, getPlaylistPath } from '../../../constants/requestPaths';
import { museApiFetcher } from '../../../utils/fetchers';
import { useAsyncFn } from '../../_shared/hooks';

export function useAddMusicToPlaylist({ musicId, onClose }: { musicId: string; onClose: () => void }): {
  isSubmitting: boolean;
  onSelect: (playlistId: number) => void;
} {
  const { mutate } = useSWRConfig();
  const [state, onSelect] = useAsyncFn(
    async (playlistId: number) => {
      await museApiFetcher({ method: 'PUT', path: addMusicToPlaylistPath(playlistId), data: { musicId } });

      await mutate(getPlaylistPath(playlistId), undefined, true);
      onClose();
    },
    [musicId, onClose]
  );

  return {
    isSubmitting: state.status === 'loading',
    onSelect,
  };
}
