import styled from '@emotion/styled';
import { useCallback, useState } from 'react';
import { subTextColor, textColor } from '../../../../constants/colors';
import { smartphone } from '../../../../utils/mediaQueries';
import { BasicButton } from '../../../_shared/components/Buttons';
import { IconAdd } from '../../../_shared/components/Icons';
import { usePlaylists } from '../../hooks';
import { CreatePlaylistModal } from '../CreatePlaylistModal';
import { PlaylistList } from '../PlaylistList';

export type PlaylistsPaneProps = {
  className?: string;
};

const Container = styled.div`
  padding: 112px 12px;
  width: 100%;

  ${smartphone`
    padding: 80px 12px;
  `}
`;

const TitleContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

const Title = styled.h1`
  margin: 0;
  color: ${textColor};
  font-size: 24px;
  font-weight: bold;

  ${smartphone`
    font-size: 18px;
  `}
`;

const List = styled(PlaylistList)`
  margin: 28px auto 0;

  ${smartphone`
    margin: 20px auto 0;
  `}
`;

const CreatePlaylistButton = styled(BasicButton)`
  position: absolute;
  top: 50%;
  right: 0;
  width: 32px;
  height: 32px;
  fill: ${subTextColor};
  border: 2px solid ${subTextColor};
  border-radius: 50%;
  transform: translateY(-50%);

  ${smartphone`
    width: 28px;
    height: 28px;
  `}
`;

export const PlaylistsPane = ({ ...props }: PlaylistsPaneProps) => {
  const [isOpenedCreatePlaylistModal, setIsOpenedCreatePlaylistModal] = useState(false);
  const { playlists, isFetching, isAllFetched, onLoadNext } = usePlaylists({});
  const handleClickCreatePlaylistModalButton = useCallback(() => setIsOpenedCreatePlaylistModal(true), []);
  const handleCloseCreatePlaylistModal = useCallback(() => setIsOpenedCreatePlaylistModal(false), []);

  return (
    <Container {...props}>
      <TitleContainer>
        <Title>プレイリスト</Title>
        <CreatePlaylistButton onClick={handleClickCreatePlaylistModalButton}>
          <IconAdd />
        </CreatePlaylistButton>
      </TitleContainer>
      <List playlists={playlists} isFetching={isFetching} isAllFetched={isAllFetched} onLoadNext={onLoadNext} />
      <CreatePlaylistModal isOpened={isOpenedCreatePlaylistModal} onClose={handleCloseCreatePlaylistModal} />
    </Container>
  );
};
