import { toCamel } from 'convert-keys';
import { APP_URL } from '../../constants/envs';
import { FetcherError } from '.';

export type MuseApiFetcherParameter<Data = unknown> = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  path: string;
  data?: Record<string, unknown>;
  handler?: (method: 'GET' | 'POST' | 'PUT' | 'DELETE', url: string, res: Response) => Promise<Data>;
};

export type MuseFormFetcherParameter<ResponseData = unknown> = {
  method: 'POST' | 'PUT';
  path: string;
  data: Record<string, string | Blob>;
  handler?: (method: 'GET' | 'POST' | 'PUT' | 'DELETE', url: string, res: Response) => Promise<ResponseData>;
};

async function defaultHandler<Data = unknown>(method: 'GET' | 'POST' | 'PUT' | 'DELETE', url: string, res: Response): Promise<Data> {
  if (res.ok) {
    const data: { data: Record<string, unknown> } = await res.json();
    return toCamel(data.data);
  }

  const data = await res.json();
  throw new FetcherError({
    method,
    url,
    statusCode: res.status,
    body: data,
  });
}

export async function museApiFetcher<Data = unknown>({ method = 'GET', path, data, handler = defaultHandler }: MuseApiFetcherParameter<Data>): Promise<Data> {
  const url = `${APP_URL}/api${path}`;

  const res = await fetch(url, {
    method,
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': `${location.href}`,
    },
    body: data ? JSON.stringify(data) : undefined,
  });

  return handler(method, url, res);
}

export async function museFormFetcher<ResponseData = unknown>({
  method,
  path,
  data,
  handler = defaultHandler,
}: MuseFormFetcherParameter<ResponseData>): Promise<ResponseData> {
  const url = `${APP_URL}/upload${path}`;
  const formData = new FormData();
  Object.entries(data).forEach(([name, value]) => {
    formData.append(name, value);
  });

  const res = await fetch(url, {
    method,
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: '*/*',
      'X-Requested-With': `${location.href}`,
    },
    body: formData,
  });

  return handler(method, url, res);
}
