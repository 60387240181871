import styled from '@emotion/styled';
import { PlayState } from '../../../../types/player';
import { smartphone, tablet } from '../../../../utils/mediaQueries';
import { PlayButton, SkipNextButton, SkipPrevButton } from '../Buttons';
import { PlaybackTimeLabel } from '../PlaybackTimeLabel';
import { VolumeController } from '../VolumeController';

export type PlayerControlsProps = {
  currentTime: number;
  playbackTime: number;
  volume: number;
  isMuted: boolean;
  playState: PlayState;
  disabledPlayButton: boolean;
  disabledPrevButton: boolean;
  disabledNextButton: boolean;
  disabledVolumeButton: boolean;
  onClickPlayButton: () => void;
  onClickPrevButton: () => void;
  onClickNextButton: () => void;
  onChangeVolume: (volume: number) => void;
  onChangeMute: (isMuted: boolean) => void;
  className?: string;
};

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Prev = styled(SkipPrevButton)`
  width: 32px;
  height: 32px;

  ${smartphone`
    width: 24px;
    height: 24px;
  `}
`;

const Play = styled(PlayButton)`
  width: 48px;
  height: 48px;

  ${smartphone`
    width: 32px;
    height: 32px;
  `}
`;

const Next = styled(SkipNextButton)`
  width: 32px;
  height: 32px;

  ${smartphone`
    width: 24px;
    height: 24px;
  `}
`;

const TimeLabel = styled(PlaybackTimeLabel)`
  margin: 0 8px;

  ${tablet`
    display: none;
  `}
`;

const Volume = styled(VolumeController)`
  width: 32px;
  height: 32px;

  ${smartphone`
    width: 24px;
    height: 24px;
  `}
`;

export const PlayerControls = ({
  currentTime,
  playbackTime,
  volume,
  isMuted,
  playState,
  disabledPlayButton,
  disabledPrevButton,
  disabledNextButton,
  disabledVolumeButton,
  onClickPlayButton,
  onClickPrevButton,
  onClickNextButton,
  onChangeVolume,
  onChangeMute,
  ...props
}: PlayerControlsProps) => {
  return (
    <Container {...props}>
      <Prev disabled={disabledPrevButton} onClick={onClickPrevButton} />
      <Play state={playState} disabled={disabledPlayButton} onClick={onClickPlayButton} />
      <Next disabled={disabledNextButton} onClick={onClickNextButton} />
      <TimeLabel currentTime={currentTime} playbackTime={playbackTime} />
      <Volume volume={volume} isMuted={isMuted} disabled={disabledVolumeButton} onChangeVolume={onChangeVolume} onChangeMute={onChangeMute} />
    </Container>
  );
};
