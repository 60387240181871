import styled from '@emotion/styled';
import { playerControlColor } from '../../../../constants/colors';
import { stringifyTime } from '../../../_shared/utils/stringifyTime';

export type PlaybackTimeLabelProps = {
  currentTime: number;
  playbackTime: number;
  className?: string;
};

const Label = styled.p`
  color: ${playerControlColor};
  font-size: 13px;
  line-height: 32px;
  user-select: none;
`;

export const PlaybackTimeLabel = ({ currentTime, playbackTime, ...props }: PlaybackTimeLabelProps) => {
  return (
    <Label {...props}>
      {stringifyTime(currentTime, playbackTime)} / {stringifyTime(playbackTime, playbackTime)}
    </Label>
  );
};
