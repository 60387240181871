import styled from '@emotion/styled';

export type GridItemProps = React.HTMLAttributes<HTMLDivElement> & {
  leading: number;
  trailing?: number;
  top: number;
  bottom?: number;
  className?: string;
};

const Div = styled.div<{
  leading: number;
  trailing?: number;
  top: number;
  bottom?: number;
}>`
  grid-column: ${(props) => props.leading} / ${(props) => props.leading + (props.trailing ?? 1)};
  grid-row: ${(props) => props.top} / ${(props) => props.top + (props.bottom ?? 1)};
`;

export const GridItem = (props: GridItemProps) => <Div {...props} />;
