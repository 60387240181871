import { useRef } from 'react';
import videoJS from 'video.js';
import { useVideo } from '../../hooks';

export type VideoPlayerProps = {
  src: string | null;
  onReady: (player: videoJS.Player) => void;
  className?: string;
};

export const VideoPlayer = ({ src, onReady, ...props }: VideoPlayerProps) => {
  const ref = useRef<HTMLVideoElement>(null);
  useVideo(ref, { src, onReady });

  return <video ref={ref} {...props} />;
};
