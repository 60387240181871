import { RefObject, useEffect, useState } from 'react';

export type UseIntersectionOptions<Root extends HTMLElement> = {
  root?: RefObject<Root>;
  rootMargin?: string;
  threshold?: number;
};

export function useIntersection<Target extends HTMLElement = HTMLElement, Root extends HTMLElement = HTMLElement>(
  ref: RefObject<Target>,
  options: UseIntersectionOptions<Root> = {}
) {
  const [intersection, setIntersection] = useState<IntersectionObserverEntry | null>(null);
  useEffect(() => {
    if (!ref.current) return;
    const target = ref.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.target === target) setIntersection(entry);
        });
      },
      {
        root: options.root?.current,
        rootMargin: options.rootMargin,
        threshold: options.threshold,
      }
    );
    observer.observe(target);

    return () => {
      observer.unobserve(target);
    };
  }, [ref.current, options.root, options.rootMargin, options.threshold]);

  return intersection;
}
