import { useCallback, useEffect, useState } from 'react';
import useSWRInfinite from 'swr/infinite';
import { SinglePlaylist } from '../../../../shared/types/playlist';
import { getMusicsPath } from '../../../constants/requestPaths';

function getKey(_pageInex: number, previousData: { playlists: SinglePlaylist[]; cursor: string | null } | null): string | null {
  if (!previousData) return getMusicsPath;

  if (!previousData.cursor) return null;

  return `${getMusicsPath}?cursor=${previousData.cursor}`;
}

export function useMusics(): {
  playlists: SinglePlaylist[];
  isFetching: boolean;
  isAllFetched: boolean;
  onLoadNext: () => void;
} {
  const [playlists, setPlaylists] = useState<SinglePlaylist[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isAllFetched, setIsAllFetched] = useState(false);
  const { data, error, size, setSize } = useSWRInfinite<{ playlists: SinglePlaylist[]; cursor: string | null }>(getKey);

  const handleLoadNext = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    if (!isAllFetched) setSize(size + 1);
  }, [size, isAllFetched]);

  useEffect(() => {
    if (!data && !error) {
      setIsFetching(true);
      return;
    }

    if (!data) {
      // TODO: エラー処理
      console.error('楽曲データの取得エラー');
      return;
    }

    const nextPlaylist = data.map(({ playlists }) => playlists).flat();
    setPlaylists(nextPlaylist);
    setIsAllFetched(!data[data.length - 1].cursor);
    setIsFetching(false);
  }, [data, error]);

  return {
    playlists,
    isFetching,
    isAllFetched,
    onLoadNext: handleLoadNext,
  };
}
