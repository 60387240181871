import styled from '@emotion/styled';
import { useRecoilValue } from 'recoil';
import { tabState } from '../../recoil/tab';
import { LibraryPane } from '../library/components/LibraryPane';
import { PlaylistsPane } from '../playlists/components/PlaylistsPane';

export type RoutesProps = {
  className?: string;
};

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1240px;
`;

export const Routes = ({ ...props }: RoutesProps) => {
  const tab = useRecoilValue(tabState);

  return <Container {...props}>{tab === 'library' ? <LibraryPane /> : <PlaylistsPane />}</Container>;
};
