import styled from '@emotion/styled';
import { boxShadowColor, mainColor, textColor } from '../../../../../constants/colors';
import { BasicButton } from '../../../../_shared/components/Buttons';
import { IconCheck } from '../../../../_shared/components/Icons';

export type SubmitButtonProps = {
  onClick?: () => void;
  className?: string;
};

const Button = styled(BasicButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${mainColor};
  background-color: ${textColor};
  border-radius: 50%;
  box-shadow: 0 0 8px ${boxShadowColor};
  box-sizing: border-box;

  & > svg {
    width: 70%;
  }
`;

export const SubmitButton = ({ ...props }: SubmitButtonProps) => {
  return (
    <Button {...props}>
      <IconCheck />
    </Button>
  );
};
