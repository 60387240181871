import styled from '@emotion/styled';
import { serviceName } from '../../../../../shared/constants/names';
import { LogInButton } from '../LogInButton';

export type AuthenticationPageProps = {
  className?: string;
};

const Container = styled.div`
  width: 100%;
`;

export const AuthenticationPage = ({ ...props }: AuthenticationPageProps) => {
  return (
    <Container {...props}>
      <div>Hello, {serviceName}!</div>
      <LogInButton />
    </Container>
  );
};
