import styled from '@emotion/styled';
import { memo, useCallback, useRef, useState } from 'react';
import { playerControlColor, toolbarColor } from '../../../../constants/colors';
import { mediaMimeTypes } from '../../../../constants/media';
import { smartphone } from '../../../../utils/mediaQueries';
import { IconAdd } from '../../../_shared/components/Icons';
import { MediaUploadModal } from '../MediaUploadModal';

export type MediaUploadButtonProps = {
  onAccept: (file: File) => void;
  onReject: () => void;
  className?: string;
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Button = styled.button`
  width: 100%;
  height: 100%;
  color: ${playerControlColor};
  background-color: ${toolbarColor};
  border: 2px solid currentColor;
  transition: filter 0.3s ease;

  &:hover {
    filter: brightness(1.15);
  }
`;

const PcButton = styled(Button)`
  display: block;
  border-radius: 6px;

  ${smartphone`
    display: none;
    border-radius: 50%;
  `}
`;

const SpButton = styled(Button)`
  display: none;
  border-radius: 50%;

  ${smartphone`
    display: block;
  `}
`;

const Icon = styled(IconAdd)`
  width: 100%;
  fill: currentColor;
`;

const FileInput = styled.input`
  display: none;
`;

export const MediaUploadButton = memo<MediaUploadButtonProps>(({ onAccept, onReject, ...props }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [isOpenedModal, setIsOpenedModal] = useState(false);
  const handlePcClick = useCallback(() => setIsOpenedModal(true), []);
  const handleSpClick = useCallback(() => ref.current?.click(), [ref.current]);
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        onAccept(e.target.files[0]);
        setIsOpenedModal(false);
      }
    },
    [onAccept]
  );
  const handleClose = useCallback(() => setIsOpenedModal(false), []);

  return (
    <Container {...props}>
      <PcButton type="button" onClick={handlePcClick}>
        <Icon />
      </PcButton>
      <SpButton type="button" onClick={handleSpClick}>
        <Icon />
      </SpButton>
      <FileInput type="file" ref={ref} accept={mediaMimeTypes.join(',')} onChange={handleChange} />
      <MediaUploadModal isOpened={isOpenedModal} onAccepted={onAccept} onRejected={onReject} onClose={handleClose} />
    </Container>
  );
});
