import styled from '@emotion/styled';
import { mainColor, textColor } from '../../../../constants/colors';
import { noWrapText } from '../../../../utils/mixins';
import { BasicButton } from '../Buttons';

export type DropdownItemProps = {
  text: string;
  disabled?: boolean;
  onClick: () => void;
  className?: string;
};

const Container = styled(BasicButton)`
  ${noWrapText}

  padding: 4px 8px;
  width: 100%;
  color: ${textColor};
  background-color: ${mainColor};
  font-size: 13px;
  font-weight: 500;
  text-align: left;
`;

export const DropdownItem = ({ text, disabled, onClick, ...props }: DropdownItemProps) => {
  return (
    <Container {...props} disabled={disabled} onClick={onClick}>
      {text}
    </Container>
  );
};
