import styled from '@emotion/styled';
import { useCallback } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { DynamicPlaylist } from '../../../../../shared/types/playlist';
import { boxShadowColor, mainColor, textColor } from '../../../../constants/colors';
import { extractDirtyValues } from '../../../../utils/extractDirtyValues';
import { TextInput } from '../../../_shared/components/Inputs';
import { ThreeDotsLoading } from '../../../_shared/components/Loadings';
import { SubmitButton } from '../Buttons';
const yupResolvers = require('@hookform/resolvers/yup');

export type EditDynamicPlaylistMetaPaneProps = {
  playlist: DynamicPlaylist;
  isUpdating: boolean;
  onEndEdit: () => void;
  onSubmit: (data: Partial<DynamicPlaylist>) => void;
  className?: string;
};

type EditPlaylistMeta = {
  title: string;
};

const { yupResolver }: { yupResolver: (schema: unknown) => Resolver<EditPlaylistMeta> } = yupResolvers;

const schema = yup.object().shape({
  title: yup.string().required('タイトルは必須項目です'),
});

const Container = styled.div`
  position: relative;
  padding: 12px 16px;
  width: 100%;
  height: 100%;
  background-color: ${mainColor};
  box-sizing: border-box;
`;

const TitleInput = styled(TextInput)`
  color: ${textColor};
  font-size: 18px;
`;

const Submit = styled(SubmitButton)`
  position: absolute;
  bottom: 12px;
  right: 12px;
  width: 42px;
  height: 42px;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  bottom: 12px;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  background-color: ${textColor};
  border-radius: 50%;
  box-shadow: 0 0 8px ${boxShadowColor};
  box-sizing: border-box;
`;

const Loading = styled(ThreeDotsLoading)`
  color: ${mainColor};
  font-size: 7px;
`;

export const EditDynamicPlaylistMetaPane = ({ playlist, isUpdating, onEndEdit, onSubmit, ...props }: EditDynamicPlaylistMetaPaneProps) => {
  const {
    register,
    handleSubmit: hookSubmit,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm<EditPlaylistMeta>({
    mode: 'all',
    defaultValues: { title: playlist.title },
    resolver: yupResolver(schema),
  });
  const handleSubmit = useCallback(
    (data: EditPlaylistMeta) => {
      const dirtyData = extractDirtyValues(dirtyFields, data);
      if (Object.keys(dirtyData).length === 0) {
        onEndEdit();
        return;
      }

      onSubmit(dirtyData);
    },
    [dirtyFields, onEndEdit, onSubmit]
  );

  return (
    <Container {...props}>
      <form onSubmit={hookSubmit(handleSubmit)}>
        <TitleInput {...register('title')} placeholder="楽曲タイトルを入力" hasError={!!errors.title} />
        {isUpdating ? (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        ) : (
          <Submit />
        )}
      </form>
    </Container>
  );
};
