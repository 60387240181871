import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { MusicMeta } from '../../../../shared/types/media';
import { getMusicPath } from '../../../constants/requestPaths';
import { FetcherError } from '../../../utils/fetchers';

export function useMusic(musicId: string): MusicMeta | null {
  const [music, setMusic] = useState<MusicMeta | null>(null);
  const path = useMemo(() => getMusicPath(musicId), [musicId]);
  const { data, error } = useSWR<{ music: MusicMeta }, FetcherError>(path);

  useEffect(() => {
    if (!data && !error) return;

    if (!data) {
      console.error('useMusic error');
      return;
    }

    setMusic(data.music);
  }, [data, error]);

  return music;
}
