import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useCallback } from 'react';
import { subTextColor, textColor, toolbarColor } from '../../../../constants/colors';
import { Tab as TabType } from '../../../../types/tab';

export type TabProps = {
  type: TabType;
  tabsCount: number;
  selected: boolean;
  onClick: (tab: TabType) => void;
  className?: string;
};

const Text = styled.p`
  position: relative;
  margin: 0;
  letter-spacing: 2px;
  color: ${textColor};
  user-select: none;
  z-index: 1;
`;

const Wrapper = styled.div<{ selected: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-color: ${toolbarColor};
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
  }

  ${({ selected }) =>
    selected
      ? css`
          font-weight: 600;

          &::before,
          &::after {
            width: 110%;
          }
        `
      : css`
          cursor: pointer;

          &:hover {
            &::before {
              width: 110%;
            }
          }
        `}
`;

const Container = styled.div<{ tabsCount: number; selected: boolean }>`
  position: relative;
  width: calc(100% / ${({ tabsCount }) => tabsCount});

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: ${subTextColor};
    transform: translate(-50%, 0);
    transition: all 0.3s ease;
  }

  ${({ selected }) =>
    selected
      ? css`
          &::after {
            width: 100%;
          }
        `
      : ``}
`;

export const Tab = ({ type, tabsCount, selected, onClick, ...props }: TabProps) => {
  const handleClick = useCallback(() => onClick(type), [type, onClick]);

  return (
    <Container tabsCount={tabsCount} selected={selected} onClick={handleClick} {...props}>
      <Wrapper selected={selected}>
        <Text>{type === 'playlists' ? 'プレイリスト' : 'ライブラリ'}</Text>
      </Wrapper>
    </Container>
  );
};
