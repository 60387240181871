import { MutableRefObject, useCallback, useState } from 'react';
import { useElementMove } from './useElementMove';

export type Direction = 'horizontal' | 'vertical';

export type UseSliderOptions = {
  initialValue?: number;
  direction?: Direction;
  reverse?: boolean;
  onChange?: (value: number) => void;
  onSlideStart?: (value: number) => void;
  onSlideEnd?: (value: number) => void;
};

export function useSlider<Element extends HTMLElement = HTMLElement>(
  ref: MutableRefObject<Element | null>,
  { initialValue, direction = 'horizontal', reverse = false, onChange, onSlideStart, onSlideEnd }: UseSliderOptions = {}
): {
  isSliding: boolean;
  value: number;
} {
  const [isSliding, setIsSliding] = useState(false);
  const [value, setValue] = useState(initialValue ?? 0);
  const handleMouseDown = useCallback(
    (evt: MouseEvent) => {
      const value = calcValue(ref, evt, direction, reverse) ?? 0;

      setIsSliding(true);
      setValue(value);
      if (onSlideStart) onSlideStart(value);
      if (onChange) onChange(value);
    },
    [ref.current, direction, reverse, onChange, onSlideStart]
  );
  const handleMouseMove = useCallback(
    (evt: MouseEvent) => {
      const value = calcValue(ref, evt, direction, reverse) ?? 0;

      setValue(value);
      if (onChange) onChange(value);
    },
    [ref.current, direction, reverse, onChange]
  );
  const handleMouseUp = useCallback(
    (evt: MouseEvent) => {
      const value = calcValue(ref, evt, direction, reverse) ?? 0;

      setIsSliding(false);
      if (onChange) onChange(value);
      if (onSlideEnd) onSlideEnd(value);
    },
    [ref.current, direction, reverse, onChange, onSlideEnd]
  );
  useElementMove<Element>(ref, {
    onMouseDown: handleMouseDown,
    onMouseMove: handleMouseMove,
    onMouseUp: handleMouseUp,
  });

  return {
    isSliding,
    value,
  };
}

function calcValue<Element extends HTMLElement>(ref: MutableRefObject<Element | null>, evt: MouseEvent, direction: Direction, reverse: boolean): number | null {
  if (!ref.current) return null;
  const rect = ref.current.getBoundingClientRect();
  const roughPos = calcPos(rect, evt, direction);
  const size = calcSliderSize(rect, direction);
  const pos = reverse ? size - roughPos : roughPos;

  return size === 0 ? 0 : Math.max(0, Math.min(pos, size)) / size;
}

function calcSliderSize(rect: DOMRect, direction: Direction): number {
  switch (direction) {
    case 'horizontal':
      return rect.width;
    case 'vertical':
      return rect.height;
  }
}

function calcPos(rect: DOMRect, evt: MouseEvent, direction: Direction): number {
  switch (direction) {
    case 'horizontal':
      return evt.pageX - rect.x;
    case 'vertical':
      return evt.pageY - rect.y;
  }
}
