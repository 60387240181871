import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { mainColor, subColor, textColor } from '../../../../../constants/colors';
import { basicBoxShadow } from '../../../../../utils/mixins';
import { ThreeDotsLoading } from '../../Loadings';
import { BasicButton } from '../BasicButton';

export type SubmitButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  children?: ReactNode;
  className?: string;
};

const Loading = styled(ThreeDotsLoading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 8px;
  color: ${mainColor};
  opacity: 0;
`;

const Button = styled(BasicButton)<{ loading?: boolean }>`
  ${basicBoxShadow}

  position: relative;
  padding: 4px 18px;
  color: ${textColor};
  background-color: ${subColor};
  font-size: 15px;
  font-weight: 600;
  border-radius: 2px;

  ${({ loading }) =>
    loading
      ? css`
          &:disabled {
            color: rgba(255, 255, 255, 0.3);
            filter: brightness(1.4);

            & > ${Loading} {
              opacity: 1;
            }
          }
        `
      : css`
          &:disabled {
            cursor: not-allowed;
          }
        `}
`;

export const SubmitButton = ({ type, loading, onClick, children, disabled, ...props }: SubmitButtonProps) => {
  return (
    <Button type={type ?? 'button'} loading={loading} disabled={disabled || loading} onClick={onClick} {...props}>
      {children}
      <Loading />
    </Button>
  );
};
