import styled from '@emotion/styled';
import { subTextColor } from '../../../../constants/colors';
import { IconTag } from '../../../_shared/components/Icons';
import { SlideUnderlineLabel } from '../../../_shared/components/Labels';

export type TagProps = {
  tag: string;
  className?: string;
};

const Container = styled(SlideUnderlineLabel)`
  font-size: 14px;
  font-weight: 500;
  color: ${subTextColor};
`;

const Wrapper = styled.span`
  display: flex;
  jusfity-content: flex-start;
  align-items: center;
`;

const Icon = styled(IconTag)`
  width: 16px;
  fill: currentColor;
`;

const Text = styled.span`
  margin: 0 0 0 3px;
`;

export const Tag = ({ tag }: TagProps) => {
  return (
    <Container>
      <Wrapper>
        <Icon />
        <Text>{tag}</Text>
      </Wrapper>
    </Container>
  );
};
