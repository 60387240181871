import styled from '@emotion/styled';
import { useCallback, useState } from 'react';
import { Playlist } from '../../../../../shared/types/playlist';
import { boxShadowColor } from '../../../../constants/colors';
import { VideoPlayer } from '../../../_shared/components/VideoPlayer';
import { usePlayer } from '../../hooks';
import { PlayerPane } from '../PlayerPane';
import { Toolbar } from '../Toolbar';

export type MusicPlayerProps = {
  playlist: Playlist;
  className?: string;
};

const Player = styled(PlayerPane)<{ isCollapsed: boolean }>`
  position: fixed;
  top: ${({ isCollapsed }) => (isCollapsed ? 'calc(100vh - 64px)' : 0)};
  left: 0;
  width: 100%;
  height: calc(100vh - 64px);
  box-shadow: 0 0 3px ${boxShadowColor};
  transition: top ease 0.4s;
  z-index: 10000;
`;

const $Toolbar = styled(Toolbar)`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 11000;
`;

const Video = styled(VideoPlayer)`
  position: absolute;
  visibility: hidden;
`;

export const MusicPlayer = ({ playlist, ...props }: MusicPlayerProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const {
    music,
    playlistIndex,
    jobStatus,
    playState,
    currentTime,
    playbackTime,
    bufferedTimes,
    volume,
    isMuted,
    isOnRepeat,
    disabledRepeat,
    isOnShuffle,
    disabledShuffle,
    disabledPlayButton,
    disabledPrevButton,
    disabledNextButton,
    disabledVolumeButton,
    onReady,
    onClickPlayButton,
    onSeekStart,
    onChangePlaybackTime,
    onSeekEnd,
    onChangeVolume,
    onChangeMute,
    onChangeRepeatEnabled,
    onChangeShuffleEnabled,
    onBackToPrevMusic,
    onSkipToNextMusic,
    onChangePlaylistIndex,
  } = usePlayer({ playlist });
  const handleClickPanelCollapseButton = useCallback(() => setIsCollapsed(!isCollapsed), [isCollapsed]);

  return (
    <>
      <Player
        {...props}
        isCollapsed={isCollapsed}
        music={music}
        playlist={playlist}
        playlistIndex={playlistIndex}
        playState={playState}
        onClickPlayButton={onClickPlayButton}
        onChangePlaylistIndex={onChangePlaylistIndex}
      >
        {music ? <Video src={!jobStatus || jobStatus === 'completed' ? music.src ?? null : null} onReady={onReady} /> : null}
      </Player>
      <$Toolbar
        music={music}
        playState={playState}
        currentTime={currentTime}
        playbackTime={playbackTime}
        bufferedTimes={bufferedTimes}
        volume={volume}
        isMuted={isMuted}
        isCollapsed={isCollapsed}
        isOnRepeat={isOnRepeat}
        disabledRepeatButton={disabledRepeat}
        isOnShuffle={isOnShuffle}
        disabledShuffleButton={disabledShuffle}
        disabledPlayButton={disabledPlayButton}
        disabledPrevButton={disabledPrevButton}
        disabledNextButton={disabledNextButton}
        disabledVolumeButton={disabledVolumeButton}
        onClickPlayButton={onClickPlayButton}
        onChangeMute={onChangeMute}
        onSeekStart={onSeekStart}
        onChangePlaybackTime={onChangePlaybackTime}
        onSeekEnd={onSeekEnd}
        onChangeVolume={onChangeVolume}
        onClickNextButton={onSkipToNextMusic}
        onClickPanelCollapseButton={handleClickPanelCollapseButton}
        onClickPrevButton={onBackToPrevMusic}
        onClickRepeatButton={onChangeRepeatEnabled}
        onClickShuffleButton={onChangeShuffleEnabled}
      />
    </>
  );
};
