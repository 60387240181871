import { useRecoilState } from 'recoil';
import { Playlist } from '../../../../shared/types/playlist';
import { deletePlaylistPath } from '../../../constants/requestPaths';
import { playlistState } from '../../../recoil/playlist';
import { museApiFetcher } from '../../../utils/fetchers';
import { useAsyncFn } from '../../_shared/hooks';

export function useDeletePlaylist({ playlist }: { playlist: Playlist }): {
  isDeleting: boolean;
  onDelete: () => void;
} {
  const [_playlist, setPlaylist] = useRecoilState(playlistState);
  const [state, onDelete] = useAsyncFn(async () => {
    if (playlist.type === 'single') return;

    await museApiFetcher({ method: 'DELETE', path: deletePlaylistPath(playlist.id) });
    setPlaylist(null);
  }, [playlist]);

  return {
    isDeleting: state.status === 'loading',
    onDelete,
  };
}
