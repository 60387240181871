import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useCallback, useMemo, useRef } from 'react';
import { accentColor } from '../../../../constants/colors';
import { useSlider } from '../../../_shared/hooks';

export type PlaybackTimeSliderProps = {
  currentTime: number;
  playbackTime: number;
  bufferedTimes: { start: number; end: number }[];
  onSeekStart: () => void;
  onSeek: (time: number) => void;
  onSeekEnd: () => void;
  className?: string;
};

const sliderHeight = 4;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: ${sliderHeight}px;
`;

const Background = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: ${sliderHeight}px;
  background-color: #adadad;
  transform: translate(0, -50%);
  transition: height 0.2s ease;
`;

const Gauge = styled.div<{ value: number }>`
  position: absolute;
  top: 50%;
  left: 0;
  width: calc(${({ value }) => value} * 100%);
  height: 100%;
  background-color: ${accentColor};
  transform: translate(0, -50%);
  transform-origin: left center;
  transition: height 0.2s ease;
`;

const BufferedGauge = styled.div<{ start: number; end: number }>`
  position: absolute;
  top: 50%;
  left: calc(${({ start }) => start} * 100%);
  width: calc(${({ start, end }) => end - start} * 100%);
  height: 100%;
  background-color: #b597f3;
  transform: translate(0, -50%);
  transform-origin: left center;
  transition: height 0.2s ease;
`;

const Handle = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  width: 0;
  height: 0;
  background-color: ${accentColor};
  border-radius: 50%;
  transform: translate(50%, -50%);
  opacity: 0;
  transition: width height opacity 0.4s ease;
`;

const HoverHandler = styled.div<{ isSliding: boolean }>`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 36px;
  transform: translate(0, -50%);
  transition: height 0.2s ease;
  cursor: pointer;

  &:hover {
    ${Background} {
      height: calc(${sliderHeight}px + 2px);
    }

    ${Handle} {
      width: 13px;
      height: 13px;
      opacity: 1;
    }
  }

  ${({ isSliding }) =>
    isSliding
      ? css`
          cursor: pointer;

          & ${Background} {
            height: calc(${sliderHeight}px + 2px);

            & > ${Gauge} {
              & > ${Handle} {
                width: 15px;
                height: 15px;
                opacity: 1;
              }
            }
          }
        `
      : ''}
`;

export const PlaybackTimeSlider = ({ currentTime, playbackTime, bufferedTimes, onSeekStart, onSeek, onSeekEnd, ...props }: PlaybackTimeSliderProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const value = useMemo(() => calcValue(currentTime, playbackTime), [currentTime, playbackTime]);
  const handleChange = useCallback(
    (value: number) => {
      onSeek(Math.round(playbackTime * value));
    },
    [playbackTime, onSeek]
  );
  const { isSliding } = useSlider(ref, {
    initialValue: calcValue(currentTime, playbackTime),
    onSlideStart: onSeekStart,
    onChange: handleChange,
    onSlideEnd: onSeekEnd,
  });

  return (
    <Container ref={ref} {...props}>
      <HoverHandler isSliding={isSliding}>
        <Background>
          {bufferedTimes.map((bufferedTime, i) => (
            <BufferedGauge key={i} start={calcValue(bufferedTime.start, playbackTime)} end={calcValue(bufferedTime.end, playbackTime)} />
          ))}
          <Gauge value={value}>
            <Handle />
          </Gauge>
        </Background>
      </HoverHandler>
    </Container>
  );
};

function calcValue(now: number, total: number) {
  if (total === 0) return 0;

  return now / total;
}
