import { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import { playlistState } from '../../recoil/playlist';
import { MusicPlayer } from './components/MusicPlayer';

export type MusicPlayerPageProps = {
  className?: string;
};

export const MusicPlayerPage = ({ ...props }: MusicPlayerPageProps) => {
  const playlist = useRecoilValue(playlistState);

  if (!playlist) return null;

  return (
    <Suspense fallback={<></>}>
      <MusicPlayer playlist={playlist} {...props} />
    </Suspense>
  );
};
