import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import videoJS from 'video.js';

export type UseVideoOptins = {
  src: string | null;
  onReady: (player: videoJS.Player) => void;
};

export function useVideo(
  ref: RefObject<HTMLVideoElement>,
  { src, onReady }: UseVideoOptins
): {
  player: videoJS.Player | null;
} {
  const playerRef = useRef<videoJS.Player | null>(null);
  const [ready, setReady] = useState(false);

  const handleLoaded = useCallback(() => setReady(true), []);

  useEffect(() => {
    if (!ref.current) return;

    playerRef.current = videoJS(
      ref.current,
      {
        controls: false,
        preload: 'auto',
      },
      () => {
        if (!playerRef.current) return;
        onReady(playerRef.current);
      }
    );

    return () => {
      const player = playerRef.current;
      if (!player) return;

      if (ready) player.dispose();
      playerRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (!playerRef.current || !src) return;

    playerRef.current.src({
      src,
      type: 'application/x-mpegURL',
    });
  }, [src]);

  useEffect(() => {
    const player = playerRef.current;
    if (!player) return;

    player.on('loadedmetadata', handleLoaded);

    return () => {
      player.off('loadedmetadata', handleLoaded);
    };
  }, [playerRef.current]);

  return {
    player: playerRef.current,
  };
}
