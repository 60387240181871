import { useState } from 'react';
import { useLocalStorage } from '../../_shared/hooks';

/**
 * 音量を管理するフック
 */
export function useVolume(): {
  volume: number;
  isMuted: boolean;
  disabled: boolean;
  onChange: (volume: number) => void;
  onChangeMute: (isMuted: boolean) => void;
} {
  const [volume, onChangeVolume] = useLocalStorage<number>('player-volume');
  const [isMuted, onChangeMute] = useLocalStorage<boolean>('player-muted');
  const [disabled, _setDisabled] = useState(false);

  return {
    volume: volume ?? 0.5,
    isMuted: isMuted ?? false,
    disabled,
    onChange: onChangeVolume,
    onChangeMute,
  };
}
