export function extractDirtyValues<T extends Record<string, unknown>>(dirtyFields: Partial<Record<keyof T, unknown>>, data: T): Partial<T> {
  const dirties: Partial<T> = {};

  Object.keys(dirtyFields).forEach((key) => {
    const k = key as keyof T;
    if (!dirtyFields[k]) return;

    dirties[k] = data[k];
  });

  return dirties;
}
