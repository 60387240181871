import { RefObject, useEffect, useState } from 'react';

export type UseMeasureRect = {
  top: number;
  bottom: number;
  left: number;
  right: number;
  width: number;
  height: number;
  x: number;
  y: number;
};

export function useMeasure<E extends Element>(ref: RefObject<E>): UseMeasureRect {
  const [rect, setRect] = useState<UseMeasureRect>({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const target = ref.current;
    if (!target) return;

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const rect = entry.target.getBoundingClientRect();
        setRect({
          top: rect.top,
          bottom: rect.bottom,
          left: rect.left,
          right: rect.right,
          width: rect.width,
          height: rect.height,
          x: rect.x,
          y: rect.y,
        });
      }
    });
    observer.observe(target);

    return () => {
      observer.unobserve(target);
    };
  }, [ref.current]);

  return rect;
}
