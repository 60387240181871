import styled from '@emotion/styled';
import { memo, ReactNode } from 'react';

export type AppTemplateProps = {
  header: ReactNode;
  player: ReactNode;
  children?: ReactNode;
  className?: string;
};

const Container = styled.div`
  position: relative;
  padding: 0;
  width: 100%;
`;

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`;

export const AppTemplate = memo<AppTemplateProps>(({ header, player, children, ...props }) => {
  return (
    <Container {...props}>
      {player}
      <Header>{header}</Header>
      {children}
    </Container>
  );
});
