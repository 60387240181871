import styled from '@emotion/styled';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { MusicMeta } from '../../../../../shared/types/media';
import { Playlist } from '../../../../../shared/types/playlist';
import { boxShadowColor, glassMainColor, mainColor } from '../../../../constants/colors';
import { smartphoneSize } from '../../../../constants/sizes';
import { PlayState } from '../../../../types/player';
import { smartphone } from '../../../../utils/mediaQueries';
import { GlassImage } from '../../../_shared/components/GlassImage';
import { useWindowSize } from '../../../_shared/hooks';
import { PlaylistSelectModal } from '../../../playlists/components/PlaylistSelectModal';
import { useUpdateMusic, useUpdateMusicThumbnail, useUpdatePlaylist, useUpdatePlaylistThumbnail } from '../../hooks';
import { EditMusicMetaPane } from '../EditMusicMetaPane';
import { EditPlaylistMetaPane } from '../EditPlaylistMetaPane';
import { MusicJacket } from '../MusicJacket';
import { MusicMetaPane } from '../MusicMetaPane';
import { PlaylistMetaPane } from '../PlaylistMetaPane';
import { Tabs, TabsItem } from '../Tabs';

export type PlayerPaneProps = {
  music: MusicMeta | null;
  playlist: Playlist;
  playlistIndex: number;
  playState: PlayState;
  onClickPlayButton: () => void;
  onChangePlaylistIndex: (playlistIndex: number) => void;
  children?: ReactNode;
  className?: string;
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  ${smartphone`
    display: block;
    width: 100%;
  `}
`;

const SpJacketWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 50vw;
`;

const Background = styled(GlassImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const NoThumbnailBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${mainColor};
`;

const Jacket = styled(MusicJacket)`
  max-width: ${(60 * 80) / 100}%;
  max-height: 85%;

  ${smartphone`
    max-width: 100%;
    max-height: 50vw;
  `}
`;

const TabsWrapper = styled.div`
  position: relative;
  width: ${(40 * 80) / 100}%;
  height: 75%;
  border-radius: 3px;
  box-shadow: 0 0 12px ${boxShadowColor};

  ${smartphone`
    width: 100%;
    height: calc(100vh - 50vw - 64px);
    border-radius: 0;
    box-shadow: none;
  `}
`;

const MusiscDetailTabs = styled(Tabs)`
  border-radius: 3px;
  overflow: hidden;

  ${smartphone`
    border-radius: 0;
  `}
`;

function getTabsString(playlist: Playlist): string[] {
  switch (playlist.type) {
    case 'single': {
      return ['楽曲情報'];
    }
    case 'dynamic':
    case 'searched': {
      return ['楽曲情報', 'プレイリスト情報'];
    }
  }
}

const JacketWrapper = ({ children }: { children: ReactNode }) => {
  const { width } = useWindowSize();

  if (width <= smartphoneSize) return <SpJacketWrapper>{children}</SpJacketWrapper>;

  return <>{children}</>;
};

export const PlayerPane = ({ music, playlist, playlistIndex, playState, onClickPlayButton, onChangePlaylistIndex, children, ...props }: PlayerPaneProps) => {
  const [isEditingMusic, setIsEditingMusic] = useState(false);
  const [isEditingPlaylist, setIsEditingPlaylist] = useState(false);
  const [isOpenedPlaylistSelectModal, setIsOpenedPlaylistSelectModal] = useState(false);
  const tabs = useMemo(() => getTabsString(playlist), [playlist.type]);
  const handleClickEditMusicButton = useCallback(() => setIsEditingMusic(true), []);
  const handleCompleteEditMusic = useCallback(() => setIsEditingMusic(false), []);
  const handleClickEditPlaylistButton = useCallback(() => setIsEditingPlaylist(true), []);
  const handleCompleteEditPlaylist = useCallback(() => setIsEditingPlaylist(false), []);
  const handleClickAddPlaylistButton = useCallback(() => setIsOpenedPlaylistSelectModal(true), []);
  const handleClosePlaylistSelectModal = useCallback(() => setIsOpenedPlaylistSelectModal(false), []);
  const { isUpdating: isUpdatingMusicMeta, onUpdate: onUpdateMusic } = useUpdateMusic(music?.id ?? null, handleCompleteEditMusic);
  const {
    isUpdating: isUpdatingMusicThumbnail,
    onAccept: onAcceptNewMusicThumbnail,
    onReject: onRejectNewMusicThumbnail,
  } = useUpdateMusicThumbnail(music?.id ?? null);
  const { isUpdating: isUpdatingPlaylist, onUpdate: onUpdatePlaylist } = useUpdatePlaylist(playlist, handleCompleteEditPlaylist);
  const {
    isUpdating: isUpdatingPlaylistThumbnail,
    onAccept: onAcceptNewPlaylistThumbnail,
    onReject: onRejectNewPlaylistThumbnail,
  } = useUpdatePlaylistThumbnail(playlist);

  return (
    <Container {...props}>
      <JacketWrapper>
        {music ? <Background src={music.thumbnailUrl} glassColor={glassMainColor} blur={13} /> : <NoThumbnailBackground />}
        <Jacket
          music={music}
          playState={playState}
          isEditingMusic={isEditingMusic}
          isEditingPlaylist={isEditingPlaylist}
          onClickPlayButton={onClickPlayButton}
          onAcceptNewMusicThumbnail={onAcceptNewMusicThumbnail}
          onRejectNewMusicThumbnail={onRejectNewMusicThumbnail}
          onAcceptNewPlaylistThumbnail={onAcceptNewPlaylistThumbnail}
          onRejectNewPlaylistThumbnail={onRejectNewPlaylistThumbnail}
        />
      </JacketWrapper>
      <TabsWrapper>
        {music ? (
          <MusiscDetailTabs tabs={tabs}>
            <TabsItem>
              {isEditingMusic ? (
                <EditMusicMetaPane
                  music={music}
                  isUpdating={isUpdatingMusicMeta ?? isUpdatingMusicThumbnail}
                  onEndEdit={handleCompleteEditMusic}
                  onSubmit={onUpdateMusic}
                />
              ) : (
                <MusicMetaPane
                  music={music}
                  isEditingPlaylist={isEditingPlaylist}
                  onClickEditButton={handleClickEditMusicButton}
                  onClickAddPlaylistButton={handleClickAddPlaylistButton}
                />
              )}
            </TabsItem>
            <TabsItem>
              {isEditingPlaylist && playlist.type !== 'single' ? (
                <EditPlaylistMetaPane
                  playlist={playlist}
                  isUpdating={isUpdatingPlaylist ?? isUpdatingPlaylistThumbnail}
                  onEndEdit={handleCompleteEditPlaylist}
                  onSubmit={onUpdatePlaylist}
                />
              ) : (
                <PlaylistMetaPane
                  playlist={playlist}
                  playlistIndex={playlistIndex}
                  isEditingMusic={isEditingMusic}
                  onChangePlaylistIndex={onChangePlaylistIndex}
                  onClickEditButton={handleClickEditPlaylistButton}
                />
              )}
            </TabsItem>
          </MusiscDetailTabs>
        ) : null}
      </TabsWrapper>
      {children}
      {music ? <PlaylistSelectModal isOpened={isOpenedPlaylistSelectModal} musicId={music.id} onClose={handleClosePlaylistSelectModal} /> : null}
    </Container>
  );
};
