import styled from '@emotion/styled';
import { KeyboardEvent, useCallback, useState } from 'react';
import { Tag } from '../NamesInput/Tag';
import { TextInput } from '../TextInput';

export type TagsInputProps = {
  defaultTags?: string[];
  placeholder?: string;
  hasError?: boolean;
  onChange: (tags: string[]) => void;
  className?: string;
};

const Container = styled.div`
  width: 100%;
`;

const Tags = styled.div`
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin-right: 8px;
    margin-bottom: 8px;
  }
`;

const Input = styled(TextInput)`
  padding: 5px 8px;
  font-size: 14px;
`;

export const TagsInput = ({ defaultTags, placeholder, hasError, onChange, ...props }: TagsInputProps) => {
  const [tags, setTags] = useState<Set<string>>(new Set(defaultTags));
  const addTag = useCallback(
    (tag: string) => {
      const newTags = new Set(tags);

      tag
        .split(/\s/)
        .filter((t) => t.length > 0)
        .forEach((tag) => {
          newTags.add(tag.trim());
        });

      setTags(newTags);
      onChange([...newTags]);
    },
    [tags]
  );
  const removeTag = useCallback(
    (tag: string) => {
      const newTags = new Set(tags);
      newTags.delete(tag);

      setTags(newTags);
      onChange([...newTags]);
    },
    [tags]
  );
  const handleKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter' && e.key !== ' ') return;
      if (!(e.target instanceof HTMLInputElement)) return;
      if (e.target.value.length === 0) return;

      addTag(e.target.value.trim());
      e.target.value = '';
      e.preventDefault();
    },
    [addTag]
  );

  return (
    <Container {...props}>
      {tags.size > 0 ? (
        <Tags>
          {[...tags].map((name) => (
            <Tag key={name} name={name} onRemove={removeTag} />
          ))}
        </Tags>
      ) : null}
      <Input placeholder={placeholder} hasError={hasError} onKeyPress={handleKeyPress} />
    </Container>
  );
};
