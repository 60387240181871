import styled from '@emotion/styled';
import { memo } from 'react';
import { useRecoilState } from 'recoil';
import { toolbarColor } from '../../../../constants/colors';
import { tabState } from '../../../../recoil/tab';
import { smartphone } from '../../../../utils/mediaQueries';
import { MediaUploadForm } from '../../../upload/components/MediaUploadForm';
import { Tab } from '../Tab';

export type ToolbarProps = {
  className?: string;
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 48px;
  background-color: ${toolbarColor};
`;

const UploadButton = styled(MediaUploadForm)`
  position: absolute;
  bottom: 50%;
  right: 6px;
  transform: translate(0, 50%);

  ${smartphone`
    bottom: -12px;
    left: 50%;
    transform: translate(-50%, 0);
  `}
`;

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0 auto;
  width: 50%;
  height: 100%;

  ${smartphone`
    width: 100%;
  `}
`;

export const Toolbar = memo<ToolbarProps>(({ ...props }) => {
  const [tab, setTab] = useRecoilState(tabState);

  return (
    <Container {...props}>
      <Tabs>
        <Tab type="playlists" tabsCount={2} selected={tab === 'playlists'} onClick={setTab} />
        <Tab type="library" tabsCount={2} selected={tab === 'library'} onClick={setTab} />
      </Tabs>
      <UploadButton />
    </Container>
  );
});
