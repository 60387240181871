import { css } from '@emotion/react';
import { boxShadowColor } from '../constants/colors';

export const scrollableY = css`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

export const ellipsis = (line = 1) => css`
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: ${line};
  -webkit-box-orient: vertical;
`;

export const basicBoxShadow = css`
  box-shadow: 0 0 8px ${boxShadowColor};
`;

export const noWrapText = css`
  white-space: nowrap;
`;

export const brightness = (num: number) => css`
  filter: brightness(${num});
`;
