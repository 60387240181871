import styled from '@emotion/styled';
import { smartphone } from '../../../../utils/mediaQueries';
import { RepeatButton, ShuffleButton, PanelCollapseButton } from '../Buttons';

export type PlaylistControlsProps = {
  isCollapsed: boolean;
  isOnRepeat: boolean;
  disabledRepeatButton: boolean;
  isOnShuffle: boolean;
  disabledShuffleButton: boolean;
  onClickPanelCollapseButton: () => void;
  onClickRepeatButton: () => void;
  onClickShuffleButton: () => void;
  className?: string;
};

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Repeat = styled(RepeatButton)`
  width: 28px;
  height: 28px;

  ${smartphone`
    width: 24px;
    height: 24px;
  `}
`;

const Shuffle = styled(ShuffleButton)`
  margin: 0 0 0 8px;
  width: 28px;
  height: 28px;

  ${smartphone`
    margin: 0 0 0 2px;
    width: 24px;
    height: 24px;
  `}
`;

const PanelCollapse = styled(PanelCollapseButton)`
  margin: 0 0 0 8px;
  width: 42px;
  height: 42px;

  ${smartphone`
    margin: 0 0 0 2px;
    width: 32px;
    height: 32px;
  `}
`;

export const PlaylistControls = ({
  isCollapsed,
  isOnRepeat,
  disabledRepeatButton,
  isOnShuffle,
  disabledShuffleButton,
  onClickPanelCollapseButton,
  onClickRepeatButton,
  onClickShuffleButton,
  ...props
}: PlaylistControlsProps) => {
  return (
    <Container {...props}>
      <Repeat on={isOnRepeat} disabled={disabledRepeatButton} onClick={onClickRepeatButton} />
      <Shuffle on={isOnShuffle} disabled={disabledShuffleButton} onClick={onClickShuffleButton} />
      <PanelCollapse isCollapsed={isCollapsed} onClick={onClickPanelCollapseButton} />
    </Container>
  );
};
