import styled from '@emotion/styled';
import { useMemo } from 'react';
import { MusicMeta } from '../../../../../shared/types/media';
import { smartphoneSize } from '../../../../constants/sizes';
import { PlayState } from '../../../../types/player';
import { smartphone } from '../../../../utils/mediaQueries';
import { Grid, GridItem, Flex } from '../../../_shared/components/Boxes';
import { useWindowSize } from '../../../_shared/hooks';
import { PlaybackTimeSlider } from '../PlaybackTimeSlider';
import { PlayerControls } from '../PlayerControls';
import { PlaylistControls } from '../PlaylistControls';
import { ToolbarMusicMeta } from '../ToolbarMusicMeta';

export type ToolbarProps = {
  music: MusicMeta | null;
  currentTime: number;
  playbackTime: number;
  bufferedTimes: { start: number; end: number }[];
  volume: number;
  isMuted: boolean;
  playState: PlayState;
  isCollapsed: boolean;
  isOnRepeat: boolean;
  disabledRepeatButton: boolean;
  isOnShuffle: boolean;
  disabledShuffleButton: boolean;
  disabledPlayButton: boolean;
  disabledPrevButton: boolean;
  disabledNextButton: boolean;
  disabledVolumeButton: boolean;
  onClickPlayButton: () => void;
  onClickPrevButton: () => void;
  onClickNextButton: () => void;
  onChangeVolume: (volume: number) => void;
  onChangeMute: (isMuted: boolean) => void;
  onSeekStart: () => void;
  onChangePlaybackTime: (playbackTime: number) => void;
  onSeekEnd: () => void;
  onClickPanelCollapseButton: () => void;
  onClickRepeatButton: () => void;
  onClickShuffleButton: () => void;
  className?: string;
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 64px;
  background-color: #2a2348;
`;

const Slider = styled(PlaybackTimeSlider)`
  position: absolute;
  top: 0;
  left: 0;
`;

const Bottom = styled(Grid)`
  padding: 4px 0 0;
  height: 100%;
  box-sizing: border-box;
`;

const PlayerC = styled(PlayerControls)`
  padding: 0 0 0 8px;
  height: 100%;

  ${smartphone`
    padding: 0;
  `}
`;

const PlaylistC = styled(PlaylistControls)`
  padding: 0 8px 0 0;
  height: 100%;

  ${smartphone`
    padding: 0;
  `}
`;

const Center = styled(Flex)`
  width: 100%;
  height: 100%;

  ${smartphone`
    padding: 0 4px;
  `}
`;

const Meta = styled(ToolbarMusicMeta)`
  margin: 0 auto;
  max-width: 100%;
`;

export const Toolbar = ({
  music,
  currentTime,
  playbackTime,
  bufferedTimes,
  volume,
  isMuted,
  playState,
  isCollapsed,
  isOnRepeat,
  disabledRepeatButton,
  isOnShuffle,
  disabledShuffleButton,
  disabledPlayButton,
  disabledPrevButton,
  disabledNextButton,
  disabledVolumeButton,
  onClickPlayButton,
  onClickPrevButton,
  onClickNextButton,
  onChangeVolume,
  onChangeMute,
  onSeekStart,
  onChangePlaybackTime,
  onSeekEnd,
  onClickPanelCollapseButton,
  onClickRepeatButton,
  onClickShuffleButton,
  ...props
}: ToolbarProps) => {
  const { width } = useWindowSize();
  const columns = useMemo(() => (width <= smartphoneSize ? '104px calc(100% - 104px - 84px) 84px' : '1fr 480px 1fr'), [width]);

  return (
    <Container {...props}>
      <Slider
        currentTime={currentTime}
        playbackTime={playbackTime}
        bufferedTimes={bufferedTimes}
        onSeek={onChangePlaybackTime}
        onSeekStart={onSeekStart}
        onSeekEnd={onSeekEnd}
      />
      <Bottom columns={columns}>
        <GridItem top={1} leading={1}>
          <PlayerC
            currentTime={currentTime}
            playbackTime={playbackTime}
            volume={volume}
            isMuted={isMuted}
            playState={playState}
            disabledPlayButton={disabledPlayButton}
            disabledPrevButton={disabledPrevButton}
            disabledNextButton={disabledNextButton}
            disabledVolumeButton={disabledVolumeButton}
            onChangeMute={onChangeMute}
            onChangeVolume={onChangeVolume}
            onClickNextButton={onClickNextButton}
            onClickPrevButton={onClickPrevButton}
            onClickPlayButton={onClickPlayButton}
          />
        </GridItem>
        <GridItem top={1} leading={2}>
          <Center justifyContent="center" alignItems="middle">
            <Meta music={music} />
          </Center>
        </GridItem>
        <GridItem top={1} leading={3}>
          <PlaylistC
            isCollapsed={isCollapsed}
            isOnRepeat={isOnRepeat}
            disabledRepeatButton={disabledRepeatButton}
            isOnShuffle={isOnShuffle}
            disabledShuffleButton={disabledShuffleButton}
            onClickPanelCollapseButton={onClickPanelCollapseButton}
            onClickRepeatButton={onClickRepeatButton}
            onClickShuffleButton={onClickShuffleButton}
          />
        </GridItem>
      </Bottom>
    </Container>
  );
};
