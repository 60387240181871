import { ClassNames } from '@emotion/react';
import styled from '@emotion/styled';
import { memo, useCallback, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { boxShadowColor, mainColor, textColor } from '../../../../constants/colors';
import { modalZIndex } from '../../../../constants/zIndex';
import { ellipsis } from '../../../../utils/mixins';
import { BasicButton } from '../../../_shared/components/Buttons';
import { LoadingPane } from '../../../_shared/components/LoadingPane';
import { ThreeDotsLoading } from '../../../_shared/components/Loadings';
import { useIntersection } from '../../../_shared/hooks';
import { useAddMusicToPlaylist, usePlaylists } from '../../hooks';

export type PlaylistSelectModalProps = {
  isOpened: boolean;
  musicId: string;
  onClose: () => void;
  className?: string;
};

const Playlists = styled.div`
  width: 100%;
  height: 100%;
`;

const Playlist = styled(BasicButton)`
  ${ellipsis()}

  display: block;
  padding: 8px 8px;
  width: 100%;
  color: ${textColor};
  background-color: ${mainColor};
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  cursor: pointer;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  font-size: 8px;

  & > * {
    color: #fff;
  }
`;

const Detector = styled.div`
  width: 100%;
  height: 100%;
`;

const LoadDetector = ({ onLoadNext }: { onLoadNext: () => void }) => {
  const ref = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(ref);
  useEffect(() => {
    if (!intersection?.isIntersecting) return;

    onLoadNext();
  }, [intersection]);

  return <Detector ref={ref} />;
};

export const PlaylistSelectModal = memo<PlaylistSelectModalProps>(({ isOpened, musicId, onClose, ...props }) => {
  const { playlists, isFetching, isAllFetched, onLoadNext } = usePlaylists({});
  const { isSubmitting, onSelect } = useAddMusicToPlaylist({ musicId, onClose });

  const handleClose = useCallback(() => {
    if (!isSubmitting) onClose();
  }, [isSubmitting, onClose]);

  return (
    <ClassNames>
      {({ css }) => (
        <Modal
          isOpen={isOpened}
          overlayClassName={css`
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(255, 255, 255, 0.3);
            z-index: ${modalZIndex};
          `}
          className={css`
            position: absolute;
            top: 50%;
            left: 50%;
            padding: 18px 16px;
            max-width: calc(100% - 32px);
            width: 280px;
            max-height: 400px;
            background-color: ${mainColor};
            border-radius: 6px;
            box-shadow: 0 0 8px ${boxShadowColor};
            transform: translate(-50%, -50%);
            outline: none;
            overflow-y: auto;
          `}
          onRequestClose={handleClose}
          {...props}
        >
          <Playlists>
            {playlists.map((playlist) => (
              <Playlist key={playlist.id} title={playlist.title} onClick={() => onSelect(playlist.id)}>
                {playlist.title}
              </Playlist>
            ))}
          </Playlists>
          <LoadingWrapper>{isAllFetched ? null : isFetching ? <ThreeDotsLoading /> : <LoadDetector onLoadNext={onLoadNext} />}</LoadingWrapper>
          {isSubmitting ? <LoadingPane /> : null}
        </Modal>
      )}
    </ClassNames>
  );
});
