import { useCallback, useEffect, useMemo, useState } from 'react';
import { JobStatus } from '../../../../shared/types/job';
import { MusicMeta } from '../../../../shared/types/media';
import { Playlist } from '../../../../shared/types/playlist';
import { shuffle } from '../../../../shared/utils/shuffle';
import { useCheckMusicUpdate } from './useCheckMusicUpdate';
import { useMusic } from './useMusic';

export type UsePlaylistParameter = {
  playlist: Playlist;
};

/**
 * プレイリストの状態を管理するフック
 */
export function usePlaylist({ playlist }: UsePlaylistParameter): {
  music: MusicMeta | null;
  playlistIndex: number;
  jobStatus: JobStatus | null;
  hasPrevMusic: boolean;
  hasNextMusic: boolean;
  disabledPrevButton: boolean;
  disabledNextButton: boolean;
  isOnRepeat: boolean;
  disabledRepeat: boolean;
  isOnShuffle: boolean;
  disabledShuffle: boolean;
  onBackToFirstMusic: () => void;
  onBackToPrevMusic: () => void;
  onSkipToNextMusic: () => void;
  onChangeRepeatEnabled: () => void;
  onChangeShuffleEnabled: () => void;
  onChangePlaylistIndex: (playlistIndex: number) => void;
} {
  const [playlistIndex, setPlaylistIndex] = useState(0);
  const [music, setMusic] = useState<MusicMeta | null>(null);
  const [disabledPrevButton, setDisabledPrevButton] = useState(true);
  const [disabledNextButton, setDisabledNextButton] = useState(true);
  const [isOnRepeat, setIsOnRepeat] = useState(false);
  const [disabledRepeat, _setDisabledRepeat] = useState(false);
  const [isOnShuffle, setIsOnShuffle] = useState(false);
  const [disabledShuffle, setDisabledShuffle] = useState(false);

  const originalMusics = useMemo(() => {
    switch (playlist.type) {
      case 'single': {
        return [playlist.music];
      }
      case 'dynamic':
      case 'searched': {
        return playlist.musics;
      }
    }
  }, [playlist]);
  const musics = useMemo(() => (isOnShuffle ? shuffle(originalMusics) : originalMusics), [isOnShuffle, originalMusics]);
  const musicId = useMemo(() => musics[playlistIndex].id, [musics, playlistIndex]);
  const jobId = useMemo(() => getCurrentJobId(playlist, playlistIndex), [playlist, playlistIndex]);
  const hasPrevMusic = useMemo(() => playlistIndex > 0, [playlistIndex]);
  const hasNextMusic = useMemo(() => playlistIndex < musics.length - 1, [musics, playlistIndex]);
  const fetchedMusic = useMusic(musicId);
  const { music: updatedMusic, jobStatus } = useCheckMusicUpdate(musicId, jobId);

  // リピートの有効/無効を切り替え
  const handleChangeRepeatEnabled = useCallback(() => setIsOnRepeat(!isOnRepeat), [isOnRepeat]);

  // シャッフルの有効/無効を切り替え
  const handleChangeShuffleEnabled = useCallback(() => setIsOnShuffle(!isOnShuffle), [isOnShuffle]);

  // 最初の曲に切り替える
  const handleBackToFirstMusic = useCallback(() => setPlaylistIndex(0), []);

  // ひとつ前の曲に切り替える
  const handleBackToPrevMusic = useCallback(() => setPlaylistIndex(Math.max(0, playlistIndex - 1)), [playlistIndex]);

  // 次の曲に切り替える
  const handleSkipToNextMusic = useCallback(() => setPlaylistIndex(Math.max(0, Math.min(playlistIndex + 1, musics.length - 1))), [musics, playlistIndex]);

  // 指定のインデックスの曲に切り替える
  const handleChangePlaylistIndex = useCallback((playlistIndex: number) => setPlaylistIndex(Math.max(0, Math.min(playlistIndex, musics.length - 1))), [musics]);

  // シャッフルできるプレイリストかを判定
  useEffect(() => setDisabledShuffle(musics.length < 2), [musics]);

  // 戻る/次へボタンの状態を管理
  useEffect(() => {
    setDisabledPrevButton(musics.length === 0);
    setDisabledNextButton(musics.length === 0 || playlistIndex === musics.length - 1);
  }, [musics, playlistIndex]);

  useEffect(() => {
    if (!fetchedMusic) return;
    setMusic(fetchedMusic);
  }, [fetchedMusic]);

  useEffect(() => {
    if (!updatedMusic) return;
    setMusic(updatedMusic);
  }, [updatedMusic]);

  return {
    music,
    playlistIndex,
    jobStatus,
    hasPrevMusic,
    hasNextMusic,
    disabledPrevButton,
    disabledNextButton,
    isOnRepeat,
    disabledRepeat,
    isOnShuffle,
    disabledShuffle,
    onBackToFirstMusic: handleBackToFirstMusic,
    onBackToPrevMusic: handleBackToPrevMusic,
    onSkipToNextMusic: handleSkipToNextMusic,
    onChangeRepeatEnabled: handleChangeRepeatEnabled,
    onChangeShuffleEnabled: handleChangeShuffleEnabled,
    onChangePlaylistIndex: handleChangePlaylistIndex,
  };
}

function getCurrentJobId(playlist: Playlist, _index: number): number | null {
  switch (playlist.type) {
    case 'single': {
      return playlist.music.jobId ?? null;
    }
    case 'dynamic':
    case 'searched': {
      return null;
    }
  }
}
