import { useCallback, useEffect, useState } from 'react';

/**
 * localStorageをシンプルに使えるhook
 */
export function useLocalStorage<T>(key: string): [T | null, (value: T) => void] {
  const [value, setValue] = useState<T | null>(null);
  const onChange = useCallback(
    (v: T) => {
      window.localStorage.setItem(key, JSON.stringify(v));

      setValue(v);
    },
    [key]
  );

  useEffect(() => {
    const v = window.localStorage.getItem(key);
    if (!v) return;

    setValue(JSON.parse(v));
  }, [key]);

  return [value, onChange];
}
