import { MutableRefObject, useEffect, useState } from 'react';

export type UseElementMoveOptions = {
  onMouseDown?: (evt: MouseEvent) => void;
  onMouseUp?: (evt: MouseEvent) => void;
  onMouseMove: (evt: MouseEvent) => void;
};

export function useElementMove<T extends HTMLElement = HTMLElement>(
  ref: MutableRefObject<T | null>,
  { onMouseDown, onMouseUp, onMouseMove }: UseElementMoveOptions
) {
  const [isDown, setIsDown] = useState<boolean>(false);

  useEffect(() => {
    if (!ref.current) return;

    function handleMouseDown(evt: MouseEvent) {
      if (onMouseDown) onMouseDown(evt);
      setIsDown(true);
    }

    ref.current.addEventListener('mousedown', handleMouseDown);

    return () => {
      ref.current?.removeEventListener('mousedown', handleMouseDown);
    };
  }, [ref, onMouseDown]);

  useEffect(() => {
    if (!isDown) return;

    function handleMouseMove(evt: MouseEvent) {
      onMouseMove(evt);
    }

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isDown, onMouseMove]);

  useEffect(() => {
    if (!isDown) return;

    function handleMouseUp(evt: MouseEvent) {
      if (onMouseUp) onMouseUp(evt);
      setIsDown(false);
    }

    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDown, onMouseUp]);
}
