import { css } from '@emotion/react';
import { CSSInterpolation } from '@emotion/serialize';
import { smartphoneSize, tabletSize } from '../constants/sizes';

export const breakPoint =
  (maxWidth: number) =>
  (strings: TemplateStringsArray, ...interpolations: CSSInterpolation[]) =>
    css`
      @media (max-width: ${maxWidth}px) {
        ${css(strings, ...interpolations)};
      }
    `;

export const tablet = breakPoint(tabletSize);
export const smartphone = breakPoint(smartphoneSize);
