import { keyframes, css } from '@emotion/react';
import styled from '@emotion/styled';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SinglePlaylist } from '../../../../../shared/types/playlist';
import { glassMainColor } from '../../../../constants/colors';
import { GlassImage } from '../../../_shared/components/GlassImage';
import { useIntersection } from '../../../_shared/hooks';

export type PlaylistThumbnailProps = {
  rowIndex: number;
  playlist: SinglePlaylist;
  onClick: (playlist: SinglePlaylist) => void;
  className?: string;
};

const appearAnimation = keyframes`
  0% {
    transform: scale(0.7);
    opacity: 0;
  }

  90% {
    transform: scale(1.05);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const Container = styled.div<{ isIntersected: boolean; rowIndex: number }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.6);
  opacity: 0;
  transform: scale(0.5);
  transition: all 0.3s ease;

  &:active {
    transform: scale(0.98) !important;
  }

  ${({ isIntersected, rowIndex }) =>
    isIntersected
      ? css`
          animation: 0.2s ease forwards ${appearAnimation} ${rowIndex * 0.05}s;
        `
      : ''}
`;

const Glass = styled(GlassImage)`
  position: absolute;
  border-radius: 3px;
  overflow: hidden;
`;

const Img = styled.img`
  position: relative;
  max-width: 100%;
  max-height: 100%;
  border-radius: 3px;
`;

export const PlaylistThumbnail = ({ playlist, onClick, ...props }: PlaylistThumbnailProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isIntersected, setIsIntersected] = useState(false);
  const intersection = useIntersection(ref);
  const handleClick = useCallback(() => onClick(playlist), [playlist]);

  useEffect(() => {
    if (!intersection || !intersection.isIntersecting) return;

    setIsIntersected(true);
  }, [intersection]);

  return (
    <Container ref={ref} isIntersected={isIntersected} onClick={handleClick} {...props}>
      <Glass src={playlist.music.thumbnailUrl} glassColor={glassMainColor} blur={13} />
      <Img src={playlist.music.thumbnailUrl} />
    </Container>
  );
};
