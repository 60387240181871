import styled from '@emotion/styled';
import { useCallback, useMemo } from 'react';
import { mainColor, textColor } from '../../../../constants/colors';
import { brightness, ellipsis } from '../../../../utils/mixins';
import { BasicButton } from '../../../_shared/components/Buttons';
import { DropdownMenu, DropdownItem } from '../../../_shared/components/DropdownMenu';

export type MusicItemProps = {
  id: string;
  title: string;
  thumbnailUrl: string;
  currentIndex: number;
  index: number;
  onSelect: (index: number) => void;
  onRemove: (index: number) => void;
  className?: string;
};

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const MetaButton = styled(BasicButton)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 42px 4px 8px;
  width: 100%;
  background-color: ${mainColor};

  &:disabled {
    ${brightness(1.2)}
  }
`;

const Thumbnail = styled.div<{ url: string }>`
  width: 48px;
  height: 48px;
  background-image: url(${({ url }) => url});
  background-size: cover;
  flex-shrink: 0;
`;

const Title = styled.p<{ selected: boolean }>`
  ${ellipsis()}

  margin-left: 8px;
  font-size: 14px;
  font-weight: ${({ selected }) => (selected ? 600 : 500)};
  color: ${textColor};
`;

const Menu = styled(DropdownMenu)`
  position: absolute;
  top: 50%;
  right: 12px;
  width: 24px;
  transform: translateY(-50%);
`;

export const MusicItem = ({ id, title, thumbnailUrl, currentIndex, index, onSelect, onRemove, ...props }: MusicItemProps) => {
  const selected = useMemo(() => index === currentIndex, [index, currentIndex]);
  const handleClick = useCallback(() => onSelect(index), [index, onSelect]);
  const handleRemove = useCallback(() => onRemove(index), [index, onRemove]);

  return (
    <Container {...props}>
      <MetaButton disabled={selected} onClick={handleClick}>
        <Thumbnail url={thumbnailUrl} />
        <Title selected={selected}>{title}</Title>
      </MetaButton>
      <Menu pos="topRight">
        <DropdownItem text="削除" onClick={handleRemove} />
      </Menu>
    </Container>
  );
};
