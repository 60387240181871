import styled from '@emotion/styled';
import { format } from 'fecha';
import { MusicMeta } from '../../../../../shared/types/media';
import { mainColor, subTextColor, textColor } from '../../../../constants/colors';
import { ellipsis } from '../../../../utils/mixins';
import { DropdownMenu, DropdownItem } from '../../../_shared/components/DropdownMenu';
import { SlideUnderlineLabel } from '../../../_shared/components/Labels';
import { Tag } from './Tag';

export type MusicMetaPaneProps = {
  music: MusicMeta;
  isEditingPlaylist: boolean;
  onClickEditButton: () => void;
  onClickAddPlaylistButton: () => void;
  className?: string;
};

const Container = styled.div`
  position: relative;
  padding: 12px 16px;
  width: 100%;
  height: 100%;
  background-color: ${mainColor};
  box-sizing: border-box;
`;

const Title = styled.p`
  ${ellipsis()}
  margin: 0;
  color: ${textColor};
  font-size: 18px;
  font-weight: 600;
`;

const ArtistSection = styled.div`
  margin: 6px 0 0;
  min-height: 48px;
`;

const Artists = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin-right: 8px;
    margin-bottom: 6px;
  }
`;

const Artist = styled(SlideUnderlineLabel)`
  color: ${subTextColor};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.4px;
`;

const Tags = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin-right: 14px;
    margin-bottom: 6px;
  }
`;

const CreatedAt = styled.p`
  position: absolute;
  bottom: 12px;
  left: 50%;
  font-size: 12px;
  color: ${textColor};
  transform: translate(-50%, 0);
`;

const Menu = styled(DropdownMenu)`
  position: absolute;
  bottom: 0;
  right: 12px;
`;

const ArtistText = ({ artist, ...props }: { artist: string }) => {
  return <Artist {...props}>{artist}</Artist>;
};

export const MusicMetaPane = ({ music, isEditingPlaylist, onClickEditButton, onClickAddPlaylistButton, ...props }: MusicMetaPaneProps) => {
  return (
    <Container {...props}>
      <Title>{music.title}</Title>
      <ArtistSection>
        <Artists>
          {music.units.map((unit) => (
            <ArtistText key={unit} artist={unit} />
          ))}
        </Artists>
        <Artists>
          {music.artists.map((artist) => (
            <ArtistText key={artist} artist={artist} />
          ))}
        </Artists>
      </ArtistSection>
      <Tags>
        {music.tags.map((tag) => (
          <Tag key={tag} tag={tag} />
        ))}
      </Tags>
      <CreatedAt>{format(new Date(music.createdAt), 'YYYY/MM/DD')}に登録</CreatedAt>
      <Menu pos="topRight">
        <DropdownItem text="編集" disabled={isEditingPlaylist} onClick={onClickEditButton} />
        <DropdownItem text="プレイリストに追加" onClick={onClickAddPlaylistButton} />
      </Menu>
    </Container>
  );
};
