import { DependencyList, useEffect } from 'react';
import { useAsyncFn, PromiseFunction } from './useAsyncFn';

export function useAsync<Fn extends PromiseFunction>(fn: Fn, deps: DependencyList) {
  const [_state, call] = useAsyncFn(fn, deps);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    call();
  }, [call]);
}
