// -- auth
export const getAuthPath = '/auth';

// -- upload
export const uploadMediaPath = '/media';
export const uploadMusicThumbnailPath = (musicId?: string) => `/thumbnails/musics/${musicId ?? ':id'}`;
export const uploadPlaylistThumbnailPath = (playlistId?: number) => `/thumbnails/playlists/${playlistId ?? ':id'}`;

// -- job
export const getUploadMediaJobPath = (jobId?: number) => `/media/jobs/${jobId ?? ':jobId'}`;

// -- music
export const getMusicPath = (musicId?: string) => `/musics/${musicId ?? ':id'}`;
export const updateMusicPath = (musicId?: string) => `/musics/${musicId ?? ':id'}`;
export const getMusicsPath = '/musics';

// -- playlist
export const getPlaylistsPath = '/playlists';
export const getPlaylistPath = (playlistId?: number) => `/playlists/${playlistId ?? ':id'}`;
export const createDynamicPlaylistPath = '/playlists/dynamics';
export const createSearchedPlaylistPath = '/playlists/searcheds';
export const updateDynamicPlaylistPath = (playlistId?: number) => `/playlists/dynamics/${playlistId ?? ':id'}`;
export const updateSearchedPlaylistPath = (playlistId?: number) => `/playlists/searcheds/${playlistId ?? ':id'}`;
export const deletePlaylistPath = (playlistId?: number) => `/playlists/${playlistId ?? ':id'}`;
export const addMusicToPlaylistPath = (playlistId?: number) => `/playlists/dynamics/${playlistId ?? ':id'}/musics`;
export const removeMusicFromPlaylistPath = (playlistId?: number) => `/playlists/dynamics/${playlistId ?? ':id'}/musics`;
