import { useSWRConfig } from 'swr';
import { Playlist } from '../../../../shared/types/playlist';
import { getPlaylistPath, removeMusicFromPlaylistPath } from '../../../constants/requestPaths';
import { museApiFetcher } from '../../../utils/fetchers';
import { useAsyncFn } from '../../_shared/hooks';

export function useRemoveMusicFromPlaylist({ playlist }: { playlist: Playlist }): {
  isRemoving: boolean;
  onRemove: (index: number) => void;
} {
  const { mutate } = useSWRConfig();
  const [state, onRemove] = useAsyncFn(
    async (index: number) => {
      if (playlist.type !== 'dynamic') return;
      await museApiFetcher({ method: 'DELETE', path: removeMusicFromPlaylistPath(playlist.id), data: { index } });

      await mutate(getPlaylistPath(playlist.id), undefined, true);
    },
    [playlist]
  );

  return {
    isRemoving: state.status === 'loading',
    onRemove,
  };
}
