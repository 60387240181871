import styled from '@emotion/styled';
import { useCallback } from 'react';
import { borderColor, boxShadowColor, subColor, textColor } from '../../../../../constants/colors';
import { ellipsis } from '../../../../../utils/mixins';
import { BasicButton } from '../../Buttons';
import { IconRemove } from '../../Icons';

export type TagProps = {
  name: string;
  onRemove: (name: string) => void;
  className?: string;
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  color: ${textColor};
  background-color: ${subColor};
  font-size: 14px;
  font-weight: 600;
  border-radius: 2px;
  box-shadow: 0 0 8px ${boxShadowColor};
`;

const Name = styled.p`
  ${ellipsis()}
  margin: 0;
  padding: 0 12px;
`;

const RemoveButton = styled(BasicButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 100%;
  color: currentColor;
  border-left: 2px solid ${borderColor};
  cursor: pointer;
  flex-shrink: 0;

  & > svg {
    width: 80%;
    fill: currentColor;
  }
`;

export const Tag = ({ name, onRemove, ...props }: TagProps) => {
  const handleRemove = useCallback(() => {
    onRemove(name);
  }, [name, onRemove]);

  return (
    <Container {...props}>
      <Name>{name}</Name>
      <RemoveButton type="button" onClick={handleRemove}>
        <IconRemove />
      </RemoveButton>
    </Container>
  );
};
