import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { JobStatus } from '../../../../shared/types/job';
import { MusicMeta } from '../../../../shared/types/media';
import { getMusicPath } from '../../../constants/requestPaths';
import { FetcherError, museApiFetcher } from '../../../utils/fetchers';
import { useJob } from '.';

export function useCheckMusicUpdate(
  musicId: string,
  jobId: number | null
): {
  music: MusicMeta | null;
  jobStatus: JobStatus | null;
} {
  const requestPath = useMemo(() => getMusicPath(musicId), [musicId]);
  const [musicMeta, setMusicMeta] = useState<MusicMeta | null>(null);
  const jobStatus = useJob(jobId);
  const { data, error } = useSWR<{ music: MusicMeta }, FetcherError>(jobStatus ? [requestPath, jobStatus] : null, (path) => museApiFetcher({ path }), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (!data && !error) return;
    // エラー処理
    if (!data) return;

    setMusicMeta(data.music);
  }, [data]);

  return {
    music: musicMeta,
    jobStatus,
  };
}
