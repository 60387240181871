import { useCallback, useMemo, useState } from 'react';
import { useSWRConfig } from 'swr';
import { Playlist } from '../../../../shared/types/playlist';
import { getPlaylistPath, uploadPlaylistThumbnailPath } from '../../../constants/requestPaths';
import { museFormFetcher } from '../../../utils/fetchers';
import { useAsyncFn } from '../../_shared/hooks';

export function useUpdatePlaylistThumbnail(playlist: Playlist): {
  isUpdating: boolean;
  onAccept: (file: File) => void;
  onReject: () => void;
} {
  const [isUpdating, setIsUpdating] = useState(false);
  const id = useMemo(() => (playlist.type === 'single' ? null : playlist.id), [playlist]);
  const { mutate } = useSWRConfig();
  const [_state, handleAccept] = useAsyncFn(
    async (file: File) => {
      if (!id) return;

      try {
        setIsUpdating(true);
        const { playlist } = await museFormFetcher<{ playlist: Playlist }>({
          method: 'POST',
          path: uploadPlaylistThumbnailPath(id),
          data: { thumbnail: file },
        });
        await mutate(getPlaylistPath(id), playlist, true);
        setIsUpdating(false);
      } catch (e) {
        console.error(e);
      }
    },
    [id, mutate]
  );

  const handleReject = useCallback(() => {
    // TODO: エラー処理
    console.error('サムネ画像の受け付けに失敗');
  }, []);

  return {
    isUpdating,
    onAccept: handleAccept,
    onReject: handleReject,
  };
}
