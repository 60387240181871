import { Global, css } from '@emotion/react';
import { mainColor } from '../../../../constants/colors';

export const GlobalStyles = () => (
  <Global
    styles={css`
      html,
      body {
        margin: 0;
        padding: 0;
        background-color: ${mainColor};
        font-family: 'M PLUS Rounded 1c', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        letter-spacing: 0.8px;
      }

      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }

      button {
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
      }
    `}
  />
);
