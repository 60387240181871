import styled from '@emotion/styled';
import { memo } from 'react';
import normalIcon from '../../../../assets/png/btn_google_signin_light_normal_web@2x.png';
import pressedIcon from '../../../../assets/png/btn_google_signin_light_pressed_web@2x.png';
import { smartphone } from '../../../../utils/mediaQueries';

export type LogInButtonProps = {
  className?: string;
};

const A = styled.a`
  display: block;
  width: 280px;
  aspect-ratio: 382 / 92;
  background-image: url(${normalIcon});
  background-size: cover;

  &:active {
    background-image: url(${pressedIcon});
  }

  ${smartphone`
    width: 210px;
  `}
`;

export const LogInButton = memo<LogInButtonProps>(({ ...props }) => <A href="/auth/google" rel="noreferrer noopener" {...props} />);
