import styled from '@emotion/styled';
import { KeyboardEvent, useCallback, useState } from 'react';
import { TextInput } from '../TextInput';
import { Tag } from './Tag';

export type NamesInputProps = {
  defaultNames?: string[];
  placeholder?: string;
  hasError?: boolean;
  onChange: (names: string[]) => void;
  className?: string;
};

const Container = styled.div`
  width: 100%;
`;

const Tags = styled.div`
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin-right: 8px;
    margin-bottom: 8px;
  }
`;

const Input = styled(TextInput)`
  padding: 5px 8px;
  font-size: 14px;
`;

export const NamesInput = ({ defaultNames, placeholder, hasError, onChange, ...props }: NamesInputProps) => {
  const [names, setNames] = useState<Set<string>>(new Set(defaultNames));
  const addName = useCallback(
    (name: string) => {
      const newNames = new Set(names);
      newNames.add(name);

      setNames(newNames);
      onChange([...newNames]);
    },
    [names]
  );
  const removeName = useCallback(
    (name) => {
      const newNames = new Set(names);
      newNames.delete(name);

      setNames(newNames);
      onChange([...newNames]);
    },
    [names]
  );
  const handleKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') return;
      if (!(e.target instanceof HTMLInputElement)) return;
      if (e.target.value.length === 0) return;

      addName(e.target.value.trim());
      e.target.value = '';
      e.preventDefault();
    },
    [addName]
  );

  return (
    <Container {...props}>
      {names.size > 0 ? (
        <Tags>
          {[...names].map((name) => (
            <Tag key={name} name={name} onRemove={removeName} />
          ))}
        </Tags>
      ) : null}
      <Input placeholder={placeholder} hasError={hasError} onKeyPress={handleKeyPress} />
    </Container>
  );
};
