import { ReactNode, useEffect, useState } from 'react';

export type WithDelayProps = {
  delay: number;
  children: ReactNode;
};

export const WithDelay = ({ delay, children }: WithDelayProps) => {
  const [delayed, setDelayed] = useState(false);
  useEffect(() => {
    const id = window.setTimeout(() => setDelayed(true), delay);

    return () => {
      window.clearTimeout(id);
    };
  }, []);

  if (delayed) return <>{children}</>;

  return <></>;
};
