import styled from '@emotion/styled';
import { useCallback } from 'react';
import { accentColor } from '../../../../constants/colors';
import { imageMimeTypes } from '../../../../constants/media';
import { DropzoneInput } from '../../../_shared/components/DropzoneInput';
import { IconFileUpload } from '../../../_shared/components/Icons';

export type ThumbnailDropzoneProps = {
  onAccepted: (file: File) => void;
  onRejected: () => void;
  className?: string;
};

const Dropzone = styled(DropzoneInput)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
`;

const IconInner = styled.div`
  padding: 4px;
  width: 48px;
  height: 48px;
  background-color: rgba(134, 112, 255, 0.2);
  border-radius: 50%;
  fill: ${accentColor};
`;

export const ThumbnailDropzone = ({ onAccepted, onRejected, ...props }: ThumbnailDropzoneProps) => {
  const handleAccepted = useCallback((files: File[]) => onAccepted(files[0]), [onAccepted]);

  return (
    <Dropzone accept={imageMimeTypes} onDropAccepted={handleAccepted} onDropRejected={onRejected} {...props}>
      <IconInner>
        <IconFileUpload />
      </IconInner>
    </Dropzone>
  );
};
