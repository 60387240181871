import { MutableRefObject, useCallback, useState } from 'react';
import { useElementMove } from './useElementMove';

export type useHSplitParameter = {
  initialRatio?: number;
  minRatio?: number;
  maxRatio?: number;
};

export function useHSplit<T extends HTMLElement, S extends HTMLElement>(
  buttonRef: MutableRefObject<T | null>,
  paneRef: MutableRefObject<S | null>,
  opt: useHSplitParameter = {}
): {
  ratio: number;
  isMoving: boolean;
} {
  const min = opt.minRatio ?? 0;
  const max = opt.maxRatio ?? 100;
  const [ratio, setRatio] = useState<number>(opt.initialRatio ?? 50);
  const [isMoving, setIsMoving] = useState<boolean>(false);
  const handleMouseDown = useCallback(() => {
    setIsMoving(true);
  }, []);
  const handleMouseMove = useCallback(
    (evt: MouseEvent) => {
      if (!evt.target) return;
      if (!paneRef.current) return;
      const width = paneRef.current.getBoundingClientRect().width;

      const x = Math.max(0, Math.min(width, evt.pageX - (paneRef.current.getBoundingClientRect().left + window.scrollX)));

      const ratio = (x / width) * 100;
      setRatio(Math.max(min, Math.min(max, ratio)));
    },
    [min, max, paneRef]
  );
  const handleMouseUp = useCallback(() => {
    setIsMoving(false);
  }, []);
  useElementMove<T>(buttonRef, {
    onMouseDown: handleMouseDown,
    onMouseMove: handleMouseMove,
    onMouseUp: handleMouseUp,
  });

  return {
    ratio,
    isMoving,
  };
}
