import { useCallback, useEffect, useRef } from 'react';

export function useInterval(callback: () => void, msec: number, stopInterval?: boolean) {
  const intervalRef = useRef<number | null>(null);

  const handleClear = useCallback(() => {
    if (intervalRef.current) window.clearInterval(intervalRef.current);
  }, [intervalRef.current]);

  useEffect(() => {
    if (stopInterval === true)
      return () => {
        if (intervalRef.current) window.clearInterval(intervalRef.current);
      };
    intervalRef.current = window.setInterval(callback, msec);

    return () => {
      if (intervalRef.current) window.clearInterval(intervalRef.current);
    };
  }, [stopInterval]);

  return handleClear;
}
