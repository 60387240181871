import { useCallback, useMemo, useState } from 'react';
import { useSWRConfig } from 'swr';
import { MusicMeta } from '../../../../shared/types/media';
import { getMusicPath, uploadMusicThumbnailPath } from '../../../constants/requestPaths';
import { museFormFetcher } from '../../../utils/fetchers';
import { useAsyncFn } from '../../_shared/hooks';

export function useUpdateMusicThumbnail(id: string | null): {
  isUpdating: boolean;
  onAccept: (file: File) => void;
  onReject: () => void;
} {
  const [isUpdating, setIsUpdating] = useState(false);
  const pathOfFetch = useMemo(() => (id ? getMusicPath(id) : null), [id]);
  const { mutate } = useSWRConfig();
  const [_state, handleAccept] = useAsyncFn(
    async (file: File) => {
      if (!id || !pathOfFetch) return;

      try {
        setIsUpdating(true);
        const { music } = await museFormFetcher<{ music: MusicMeta }>({
          method: 'POST',
          path: uploadMusicThumbnailPath(id),
          data: {
            thumbnail: file,
            musicId: id,
          },
        });
        await mutate(pathOfFetch, music, true);
        setIsUpdating(false);
      } catch (e) {
        console.error(e);
      }
    },
    [id, pathOfFetch, mutate]
  );

  const handleReject = useCallback(() => {
    // TODO: エラー処理
    console.error('サムネ画像の受け付けに失敗');
  }, []);

  return {
    isUpdating,
    onAccept: handleAccept,
    onReject: handleReject,
  };
}
