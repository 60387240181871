import styled from '@emotion/styled';
import { Skeleton } from '@material-ui/core';
import { memo } from 'react';
import { MusicMeta } from '../../../../../shared/types/media';
import { subTextColor, textColor } from '../../../../constants/colors';
import { generateArtistsText } from '../../../../utils/generateArtistsText';
import { smartphone } from '../../../../utils/mediaQueries';
import { MarqueeLabel } from '../../../_shared/components/Labels';

export type ToolbarMusicMetaProps = {
  music: MusicMeta | null;
  className?: string;
};

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Thumbnail = styled.img`
  width: 42px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  flex-shrink: 0;

  ${smartphone`
    display: none;
  `}
`;

const Info = styled.div`
  margin: 0 0 0 14px;
  width: calc(100% - 42px - 14px);
  height: 100%;

  ${smartphone`
    margin: 0;
    width: 100%;
  `}
`;

const Title = styled.p`
  color: ${textColor};
  font-size: 15px;
  font-weight: bold;
  line-height: 22px;
  white-space: nowrap;
`;

const Artists = styled.p`
  color: ${subTextColor};
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  white-space: nowrap;
`;

export const ToolbarMusicMeta = memo<ToolbarMusicMetaProps>(({ music, ...props }) => {
  return (
    <Container {...props}>
      {music ? <Thumbnail src={music.thumbnailUrl} /> : <Skeleton variant="rectangular" width={42} height={42} />}
      <Info>
        {music ? (
          <MarqueeLabel marqueeSpeed={10}>
            <Title>{music.title}</Title>
          </MarqueeLabel>
        ) : (
          <Skeleton variant="rectangular" width="100%" height={20} style={{ marginBottom: 2 }} />
        )}
        {music ? (
          <MarqueeLabel marqueeSpeed={10}>
            <Artists>{generateArtistsText(music.artists, music.units)}</Artists>
          </MarqueeLabel>
        ) : (
          <Skeleton variant="rectangular" width="100%" height={18} />
        )}
      </Info>
    </Container>
  );
});
