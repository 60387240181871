import styled from '@emotion/styled';
import { textColor } from '../../../../constants/colors';
import { smartphone } from '../../../../utils/mediaQueries';
import { useMusics } from '../../hooks';
import { MusicList } from '../MusicList';

export type LibraryPaneProps = {
  className?: string;
};

const Container = styled.div`
  padding: 112px 12px;
  width: 100%;

  ${smartphone`
    padding: 80px 12px;
  `}
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

const Title = styled.h1`
  margin: 0;
  color: ${textColor};
  font-size: 24px;
  font-weight: bold;

  ${smartphone`
    font-size: 18px;
  `}
`;

const List = styled(MusicList)`
  margin: 28px auto 0;

  ${smartphone`
    margin: 20px auto 0;
  `}
`;

export const LibraryPane = ({ ...props }: LibraryPaneProps) => {
  const { playlists, isFetching, isAllFetched, onLoadNext } = useMusics();

  return (
    <Container {...props}>
      <TitleContainer>
        <Title>ライブラリ</Title>
      </TitleContainer>
      <List playlists={playlists} isFetching={isFetching} isAllFetched={isAllFetched} onLoadNext={onLoadNext} />
    </Container>
  );
};
