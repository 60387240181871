import { ClassNames } from '@emotion/react';
import styled from '@emotion/styled';
import { memo, useCallback } from 'react';
import Modal from 'react-modal';
import { PlaylistSummaryType } from '../../../../../shared/types/playlist';
import { boxShadowColor, mainColor } from '../../../../constants/colors';
import { modalZIndex } from '../../../../constants/zIndex';
import { SubmitButton } from '../../../_shared/components/Buttons';
import { BasicSelect, TextInput, TagsInput, NamesInput } from '../../../_shared/components/Inputs';
import { useCreatePlaylist } from '../../hooks';

export type CreatePlaylistModalProps = {
  isOpened: boolean;
  onClose: () => void;
  className?: string;
};

const TitleInput = styled(TextInput)`
  margin: 24px 0 0;
`;

const ButtonWrapper = styled.div`
  margin: 24px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UnitsInput = styled(NamesInput)`
  margin: 18px 0 0;
`;

const ArtistsInput = styled(NamesInput)`
  margin: 18px 0 0;
`;

const TagsI = styled(TagsInput)`
  margin: 18px 0 0;
`;

export const CreatePlaylistModal = memo<CreatePlaylistModalProps>(({ isOpened, onClose, ...props }) => {
  const { playlistType, title, isSubmitting, isSubmittable, onSelect, onChangeTitle, onChangeTags, onChangeArtists, onChangeUnits, onSubmit } =
    useCreatePlaylist({ onClose });
  const handleClose = useCallback(() => {
    if (!isSubmitting) onClose();
  }, [isSubmitting, onClose]);

  return (
    <ClassNames>
      {({ css }) => (
        <Modal
          isOpen={isOpened}
          overlayClassName={css`
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(255, 255, 255, 0.3);
            z-index: ${modalZIndex};
          `}
          className={css`
            position: absolute;
            top: 50%;
            left: 50%;
            padding: 18px 16px;
            max-width: 480px;
            width: calc(100% - 32px);
            background-color: ${mainColor};
            border-radius: 6px;
            box-shadow: 0 0 8px ${boxShadowColor};
            transform: translate(-50%, -50%);
            outline: none;
          `}
          onRequestClose={handleClose}
          {...props}
        >
          <BasicSelect
            currentKey={playlistType}
            options={[
              { key: 'dynamic', value: 'dynamic' },
              { key: 'searched', value: 'searched' },
            ]}
            placeholder="プレイリストタイプ"
            onSelect={(type: string) => onSelect(type as PlaylistSummaryType)}
          />
          <TitleInput title={title} placeholder="タイトルを入力" onChange={onChangeTitle} />
          {playlistType === 'searched' ? (
            <>
              <TagsI placeholder="タグを入力" defaultTags={[]} onChange={onChangeTags} />
              <UnitsInput placeholder="ユニット名を入力" defaultNames={[]} onChange={onChangeUnits} />
              <ArtistsInput placeholder="アーティスト名を入力" defaultNames={[]} onChange={onChangeArtists} />
            </>
          ) : null}
          <ButtonWrapper>
            <SubmitButton loading={isSubmitting} disabled={!isSubmittable} onClick={onSubmit}>
              作成
            </SubmitButton>
          </ButtonWrapper>
        </Modal>
      )}
    </ClassNames>
  );
});
