import { useCallback, useEffect, useRef, useState } from 'react';
import videoJS from 'video.js';
import { JobStatus } from '../../../../shared/types/job';
import { MusicMeta } from '../../../../shared/types/media';
import { Playlist } from '../../../../shared/types/playlist';
import { range } from '../../../../shared/utils/range';
import { PlayState } from '../../../types/player';
import { usePlaylist, useVolume } from '.';

export type UsePlayerParameter = {
  playlist: Playlist;
};

/**
 * プレイヤーの状態を管理するフック
 */
export function usePlayer({ playlist }: UsePlayerParameter): {
  music: MusicMeta | null;
  playlistIndex: number;
  jobStatus: JobStatus | null;
  playState: PlayState;
  currentTime: number;
  playbackTime: number;
  bufferedTimes: { start: number; end: number }[];
  volume: number;
  isMuted: boolean;
  isOnRepeat: boolean;
  disabledRepeat: boolean;
  isOnShuffle: boolean;
  disabledShuffle: boolean;
  disabledPlayButton: boolean;
  disabledPrevButton: boolean;
  disabledNextButton: boolean;
  disabledVolumeButton: boolean;
  onReady: (player: videoJS.Player) => void;
  onClickPlayButton: () => void;
  onSeekStart: () => void;
  onChangePlaybackTime: (playbackTime: number) => void;
  onSeekEnd: () => void;
  onChangeVolume: (volume: number) => void;
  onChangeMute: (isMuted: boolean) => void;
  onChangeRepeatEnabled: () => void;
  onChangeShuffleEnabled: () => void;
  onBackToPrevMusic: () => void;
  onSkipToNextMusic: () => void;
  onChangePlaylistIndex: (playlistIndex: number) => void;
} {
  const playerRef = useRef<videoJS.Player | null>(null);
  const {
    music,
    playlistIndex,
    jobStatus,
    hasPrevMusic,
    hasNextMusic,
    disabledPrevButton,
    disabledNextButton,
    isOnRepeat,
    disabledRepeat,
    isOnShuffle,
    disabledShuffle,
    onBackToFirstMusic,
    onBackToPrevMusic,
    onSkipToNextMusic,
    onChangeRepeatEnabled,
    onChangeShuffleEnabled,
    onChangePlaylistIndex,
  } = usePlaylist({
    playlist,
  });
  const [playState, setPlayState] = useState<PlayState>('loading');
  const [playStateBeforeSeek, setPlayStateBeforeSeek] = useState<PlayState | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [playbackTime, setPlaybackTime] = useState(0);
  const [bufferedTimes, setBufferedTimes] = useState<{ start: number; end: number }[]>([]);
  const [disabledPlayButton, setDisabledPlayButton] = useState(!jobStatus || jobStatus === 'completed');
  const { volume, isMuted, disabled: disabledVolumeButton, onChange: onChangeVolume, onChangeMute } = useVolume();

  // 再生
  const play = useCallback(() => {
    const player = playerRef.current;
    if (!player) return;

    player.volume(volume);
    player.muted(isMuted);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    player.play();
  }, [playerRef, volume, isMuted]);

  // 楽曲データを読み込み終わったとき
  const handleLoaded = useCallback(() => {
    const player = playerRef.current;
    if (!player) return;

    setPlaybackTime(player.duration());
    setPlayState('ready');
    setTimeout(() => play(), 500);
  }, [play]);

  // 再生状態になったとき
  const handlePlaying = useCallback(() => setPlayState('playing'), []);

  // 一時停止状態になったとき
  const handlePause = useCallback(() => setPlayState('paused'), []);

  // 停止状態になったとき
  const handleStopped = useCallback(() => {
    if (hasNextMusic) {
      // 次の曲があるなら次へ
      onSkipToNextMusic();
    } else if (!isOnRepeat) {
      // 次の曲がなくてリピートもしないなら停止状態
      setPlayState('stopped');
    } else if (hasPrevMusic) {
      // リピートが有効で前の曲があるなら最初の曲へ
      onBackToFirstMusic();
    } else {
      // リピートが有効で前の曲もないなら今の曲をリピート
      play();
    }
  }, [hasPrevMusic, hasNextMusic, isOnRepeat, onBackToFirstMusic, onSkipToNextMusic, play]);

  // フレームを読み込み中のとき
  const handleWaiting = useCallback(() => setPlayState('loading'), []);

  // エラーが起きたとき
  const handleError = useCallback(() => setPlayState('error'), []);

  // 再生時間が変化したとき
  const handleTimeUpdate = useCallback(() => setCurrentTime(playerRef.current?.currentTime() ?? 0), []);

  // 読み込みが進んだとき
  const handleProgress = useCallback(() => {
    const player = playerRef.current;
    if (!player) return;

    // バッファ状況を取得
    const buffered = player.buffered();
    const buffers = range(0, buffered.length).map((i) => ({ start: buffered.start(i), end: buffered.end(i) }));
    setBufferedTimes(buffers);
  }, []);

  const listenPlayerEvents = useCallback(() => {
    const player = playerRef.current;
    if (!player) return;

    player.on('loadedmetadata', handleLoaded);
    player.on('progress', handleProgress);
    player.on('timeupdate', handleTimeUpdate);
    player.on('error', handleError);
    player.on('stalled', handleError);
    player.on('playing', handlePlaying);
    player.on('pause', handlePause);
    player.on('waiting', handleWaiting);
    player.on('ended', handleStopped);
  }, [handleLoaded, handleProgress, handleTimeUpdate, handleError, handlePlaying, handlePause, handleWaiting, handleStopped]);

  const unlistenPlayerEvents = useCallback(() => {
    const player = playerRef.current;
    if (!player) return;

    player.off('loadedmetadata');
    player.off('progress');
    player.off('timeupdate');
    player.off('error');
    player.off('stalled');
    player.off('playing');
    player.off('pause');
    player.off('waiting');
    player.off('ended');
  }, []);

  // video.jsの準備ができたとき
  const handleReady = useCallback(
    (player: videoJS.Player) => {
      playerRef.current = player;

      listenPlayerEvents();
      setDisabledPlayButton(false);
    },
    [listenPlayerEvents]
  );

  // 再生ボタンが押されたとき
  const handleClickPlayButton = useCallback(() => {
    const player = playerRef.current;
    if (!player) return;

    switch (playState) {
      case 'ready':
      case 'paused':
      case 'stopped': {
        play();
        break;
      }
      case 'playing': {
        player.pause();
        break;
      }
    }
  }, [playState, play]);

  // シークを開始したとき
  const handleSeekStart = useCallback(() => {
    const player = playerRef.current;
    if (!player) return;

    setPlayStateBeforeSeek(playState);
    player.pause();
  }, [playState]);

  // 再生時間が変更されたとき
  const handleChangePlaybackTime = useCallback((playbackTime: number) => {
    playerRef.current?.currentTime(playbackTime);
    // UXのために直接セットする
    setCurrentTime(playbackTime);
  }, []);

  // シークを終了したとき
  const handleSeekEnd = useCallback(() => {
    if (!playStateBeforeSeek) return;

    if (playStateBeforeSeek === 'playing') play();
    setPlayStateBeforeSeek(null);
  }, [playStateBeforeSeek, play]);

  // 前へボタンを押したとき
  const handleBackToPrevMusic = useCallback(() => {
    // 前の曲がない状況か、再生時間が10秒以上経っているときは0秒に戻す
    if (!hasPrevMusic || currentTime >= 10) {
      const player = playerRef.current;
      if (!player) return;

      player.currentTime(0);
      return;
    }

    // それ以外は前の曲に変える
    onBackToPrevMusic();
  }, [hasPrevMusic, currentTime, onBackToPrevMusic]);

  // イベントの監視
  useEffect(() => {
    const player = playerRef.current;
    if (!player) return;

    unlistenPlayerEvents();
    listenPlayerEvents();

    return () => {
      unlistenPlayerEvents();
    };
  }, [listenPlayerEvents, unlistenPlayerEvents]);

  // ボリュームが変更されたとき
  useEffect(() => {
    const player = playerRef.current;
    if (!player) return;

    player.volume(volume);
  }, [volume]);

  // ミュート状態が変更されたとき
  useEffect(() => {
    const player = playerRef.current;
    if (!player) return;

    player.muted(isMuted);
  }, [isMuted]);

  // ジョブの状態が変化したとき
  useEffect(() => {
    setDisabledPlayButton(!!jobStatus && jobStatus !== 'completed');
  }, [jobStatus]);

  return {
    music,
    playlistIndex,
    jobStatus,
    playState,
    currentTime,
    playbackTime,
    bufferedTimes,
    volume,
    isMuted,
    isOnRepeat,
    disabledRepeat,
    isOnShuffle,
    disabledShuffle,
    disabledPlayButton,
    disabledPrevButton,
    disabledNextButton,
    disabledVolumeButton,
    onReady: handleReady,
    onClickPlayButton: handleClickPlayButton,
    onSeekStart: handleSeekStart,
    onChangePlaybackTime: handleChangePlaybackTime,
    onSeekEnd: handleSeekEnd,
    onChangeVolume,
    onChangeMute,
    onChangeRepeatEnabled,
    onChangeShuffleEnabled,
    onBackToPrevMusic: handleBackToPrevMusic,
    onSkipToNextMusic,
    onChangePlaylistIndex,
  };
}
