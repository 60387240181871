import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ChangeEvent, forwardRef, InputHTMLAttributes, useCallback, useState } from 'react';
import { borderColor, subTextColor, validationErrorColor, placeholderColor } from '../../../../../constants/colors';

export type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  hasError?: boolean;
};

export const Input = styled.input<{ hasError?: boolean; isEmpty: boolean }>`
  position: relative;
  padding: 4px 8px;
  width: 100%;
  color: ${subTextColor};
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: ${({ isEmpty }) => (isEmpty ? 500 : 600)};
  border-bottom: 2px solid ${borderColor};
  transition: border-bottom 0.3s ease;

  ${({ hasError }) =>
    hasError
      ? css`
          color: ${validationErrorColor};
          border-bottom-color: ${validationErrorColor};

          &::placeholder {
            color: ${validationErrorColor};
          }
        `
      : css`
          &:focus {
            border-bottom-color: ${subTextColor};
          }

          &::placeholder {
            color: ${placeholderColor};
          }
        `}
`;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({ value, onChange, ...props }, ref) => {
  const [isEmpty, setIsEmpty] = useState(!value);
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsEmpty(e.target.value.length === 0);
      if (onChange) onChange(e);
    },
    [onChange]
  );

  return <Input ref={ref} isEmpty={isEmpty} onChange={handleChange} {...props} />;
});
