import styled from '@emotion/styled';

export type GlassImageProps = {
  src: string;
  glassColor?: string;
  blur?: number;
  className?: string;
};

const Container = styled.div<{ src: string }>`
  position: position;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-image: url(${({ src }) => src});
`;

const Glass = styled.div<{ glassColor: string; blur: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ glassColor }) => glassColor};
  backdrop-filter: blur(${({ blur }) => blur}px);
`;

export const GlassImage = ({ src, glassColor, blur, ...props }: GlassImageProps) => {
  return (
    <Container {...props} src={src}>
      <Glass glassColor={glassColor ?? 'rgba(255, 255, 255, 0.25)'} blur={blur ?? 13} />
    </Container>
  );
};
