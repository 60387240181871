import styled from '@emotion/styled';
import { memo } from 'react';
import { playerControlColor } from '../../../../../constants/colors';
import { BasicButton } from '../../../../_shared/components/Buttons';
import { IconSkipPrevious } from '../../../../_shared/components/Icons';

export type SkipPrevButtonProps = {
  disabled?: boolean;
  onClick: () => void;
  className?: string;
};

const Button = styled(BasicButton)`
  color: ${playerControlColor};
`;

export const SkipPrevButton = memo<SkipPrevButtonProps>(({ disabled, onClick, ...props }) => {
  return (
    <Button disabled={disabled} onClick={onClick} {...props}>
      <IconSkipPrevious />
    </Button>
  );
});
