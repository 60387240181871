export const mainColor = '#2d2254';
export const subColor = '#8473e1';
export const accentColor = '#7951c7';
export const accentTextColor = '#8d8fe1';
export const accentOnColor = '#b96aff';
export const textColor = '#fff';
export const subTextColor = 'rgb(203, 196, 255)';
export const boxShadowColor = 'rgba(0, 0, 0, .5)';
export const playerControlColor = '#dbd2ff';
export const borderColor = '#150a4a';
export const placeholderColor = '#d3d3d3';
export const validationErrorColor = '#d34141';
export const glassMainColor = 'rgba(152, 119, 195, 0.26)';
export const toolbarColor = '#2a2348';
