import styled from '@emotion/styled';
import { memo, useMemo } from 'react';
import { playerControlColor } from '../../../../../constants/colors';
import { PlayState } from '../../../../../types/player';
import { BasicButton } from '../../../../_shared/components/Buttons';
import { IconPause, IconPlayArrow, IconStop } from '../../../../_shared/components/Icons';

export type PlayButtonProps = {
  state: PlayState;
  disabled?: boolean;
  onClick: () => void;
  className?: string;
};

const Button = styled(BasicButton)`
  color: ${playerControlColor};
`;

const icon = (state: PlayState) => {
  switch (state) {
    case 'playing': {
      return IconPause;
    }
    case 'paused': {
      return IconPlayArrow;
    }
    case 'stopped': {
      return IconStop;
    }
    default: {
      return IconPlayArrow;
    }
  }
};

export const PlayButton = memo<PlayButtonProps>(({ state, disabled, onClick, ...props }) => {
  const Icon = useMemo(() => icon(state), [state]);

  return (
    <Button disabled={disabled} onClick={onClick} {...props}>
      <Icon />
    </Button>
  );
});
