import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { playlistState } from '../../../recoil/playlist';
import { UploadMediaResponse } from '../../../types/responses';
import { museFormFetcher } from '../../../utils/fetchers';
import { useAsyncFn } from '../../_shared/hooks';

export function useUploadMedia(): {
  isSubmitting: boolean;
  onSubmit: (file: File) => Promise<void>;
  onReject: () => void;
} {
  const [_playlist, setPlaylist] = useRecoilState(playlistState);
  const [state, onSubmit] = useAsyncFn(async (file: File) => {
    const { media } = await museFormFetcher<{ media: UploadMediaResponse }>({
      method: 'POST',
      path: '/media',
      data: {
        media: file,
      },
    });

    setPlaylist({
      type: 'single',
      music: {
        id: media.id,
        title: media.basename,
        thumbnailUrl: '',
      },
    });
  }, []);

  const handleReject = useCallback(() => {
    // TODO: エラー処理
    console.error('画像の選択に失敗');
  }, []);

  return {
    isSubmitting: state.status === 'loading',
    onSubmit,
    onReject: handleReject,
  };
}
