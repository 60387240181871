import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Skeleton } from '@material-ui/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MusicMeta } from '../../../../../shared/types/media';
import { PlayState } from '../../../../types/player';
import { IconPause, IconPlayArrow, IconExclamation } from '../../../_shared/components/Icons';
import { ThreeDotsLoading } from '../../../_shared/components/Loadings';
import { WithDelay } from '../../../_shared/components/WithDelay';
import { ThumbnailDropzone } from '../ThumbnailDropzone';

export type MusicJacketProps = {
  music: MusicMeta | null;
  playState: PlayState;
  isEditingMusic: boolean;
  isEditingPlaylist: boolean;
  onClickPlayButton: () => void;
  onAcceptNewMusicThumbnail: (file: File) => void;
  onRejectNewMusicThumbnail: () => void;
  onAcceptNewPlaylistThumbnail: (file: File) => void;
  onRejectNewPlaylistThumbnail: () => void;
  className?: string;
};

const playButtonAnimation = keyframes`
  0% {
    opacity: 0;
  }

  80% {
    opacity: .5;
  }

  100% {
    opacity: 0;
    transform: scale(1.5);
  }
`;

const Container = styled.div<{ imageWidth: number | null; imageHeight: number | null }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.6);

  ${({ imageWidth, imageHeight }) =>
    imageWidth && imageHeight
      ? css`
          aspect-ratio: ${imageWidth} / ${imageHeight};
        `
      : ''}
`;

const Thumbnail = styled.img`
  position: relative;
  max-width: 100%;
  max-height: 100%;
  border-radius: 3px;
`;

const ThumbnailSkeleton = styled(Skeleton)`
  position: relative;
  min-width: 1px;
  min-height: 1px;
  aspect-ratio: 1 / 1;
  border-radius: 3px;
`;

const PlayStateIconWrapper = styled.div<{ isHidden: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;

  & > svg {
    width: 85%;
    fill: #fff;
    opacity: 0.9;
  }

  ${({ isHidden }) =>
    isHidden
      ? css`
          visibility: hidden;
        `
      : css`
          visibility: visible;
          animation: 0.3s linear ${playButtonAnimation} forwards;
        `}
`;

const LoadingIconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
`;

const Loading = styled(ThreeDotsLoading)`
  color: #fff;
  opacity: 0.9;
  font-size: 8px;
`;

const ErrorIconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;

  & > svg {
    width: 80%;
    fill: #fff;
    opacity: 0.9;
  }
`;

const Dropzone = styled(ThumbnailDropzone)`
  position: absolute;
  border-radius: 3px;
`;

export const MusicJacket = ({
  music,
  playState,
  isEditingMusic,
  isEditingPlaylist,
  onClickPlayButton,
  onAcceptNewMusicThumbnail,
  onRejectNewMusicThumbnail,
  onAcceptNewPlaylistThumbnail,
  onRejectNewPlaylistThumbnail,
  ...props
}: MusicJacketProps) => {
  const ref = useRef<HTMLImageElement>(null);
  const skeletonRef = useRef<HTMLSpanElement>(null);
  const [isHiddenPlayButton, setIsHiddenPlayButton] = useState(true);
  const [size, setSize] = useState<{ width: number; height: number } | null>(null);
  const [skeletonSize, setSkeletonSize] = useState<{ width: number; height: number } | null>(null);
  const handleClickPlayButton = useCallback(() => {
    if (!music || isEditingMusic || isEditingPlaylist) return;
    setIsHiddenPlayButton(false);
    onClickPlayButton();
  }, [music, isEditingMusic, isEditingPlaylist, onClickPlayButton]);
  const handlePlayButtonAnimationEnd = useCallback(() => setIsHiddenPlayButton(true), []);
  useEffect(() => {
    if (!ref.current) return;
    if (size) return;

    const rect = ref.current.getBoundingClientRect();
    setSize({ width: rect.width, height: rect.height });
  }, [ref.current]);
  useEffect(() => {
    if (!skeletonRef.current) return;
    if (skeletonSize) return;

    const rect = skeletonRef.current.getBoundingClientRect();
    setSkeletonSize({ width: rect.width, height: rect.height });
  }, [skeletonRef.current]);

  return (
    <Container
      imageWidth={size?.width ?? skeletonSize?.width ?? null}
      imageHeight={size?.height ?? skeletonSize?.height ?? null}
      onClick={handleClickPlayButton}
      {...props}
    >
      {music ? (
        <Thumbnail ref={ref} src={music.thumbnailUrl} draggable={false} />
      ) : (
        <ThumbnailSkeleton ref={skeletonRef} variant="rectangular" width="3000px" height="100%" />
      )}
      {playState === 'loading' ? (
        <WithDelay delay={800}>
          <LoadingIconWrapper>
            <Loading />
          </LoadingIconWrapper>
        </WithDelay>
      ) : playState === 'error' ? (
        <ErrorIconWrapper>
          <IconExclamation />
        </ErrorIconWrapper>
      ) : (
        <PlayStateIconWrapper isHidden={isHiddenPlayButton} onAnimationEnd={handlePlayButtonAnimationEnd}>
          {playState === 'playing' ? <IconPause /> : <IconPlayArrow />}
        </PlayStateIconWrapper>
      )}
      {isEditingMusic ? <Dropzone onAccepted={onAcceptNewMusicThumbnail} onRejected={onRejectNewMusicThumbnail} /> : null}
      {isEditingPlaylist ? <Dropzone onAccepted={onAcceptNewPlaylistThumbnail} onRejected={onRejectNewPlaylistThumbnail} /> : null}
    </Container>
  );
};
