export type FetcherErrorParameter = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  url: string;
  statusCode: number;
  body: Record<string, unknown> | string;
};

export class FetcherError extends Error {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  url: string;
  statusCode: number;
  body: Record<string, unknown> | string;

  constructor({ method, url, statusCode, body }: FetcherErrorParameter) {
    super(`feiled to: ${method} ${url}`);

    this.method = method;
    this.url = url;
    this.statusCode = statusCode;
    this.body = body;
  }
}
