import styled from '@emotion/styled';
import { forwardRef } from 'react';

export type GridProps = React.HTMLAttributes<HTMLDivElement> & {
  rows?: string;
  columns?: string;
  columnGap?: number;
  rowGap?: number;
  className?: string;
};

const Div = styled.div<{
  rows?: string;
  columns?: string;
  columnGap?: number;
  rowGap?: number;
}>`
  display: grid;
  grid-template-columns: ${(props) => props.columns ?? '1fr'};
  grid-template-rows: ${(props) => props.rows ?? '1fr'};
  column-gap: ${(props) => props.columnGap ?? 0}px;
  row-gap: ${(props) => props.rowGap ?? 0}px;
`;

export const Grid = forwardRef<HTMLDivElement, GridProps>((props, ref) => <Div ref={ref} {...props} />);
