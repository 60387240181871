import { useMemo, useState } from 'react';
import { useSWRConfig } from 'swr';
import { DynamicPlaylist, Playlist, SearchedPlaylist } from '../../../../shared/types/playlist';
import { getPlaylistPath, updateDynamicPlaylistPath, updateSearchedPlaylistPath } from '../../../constants/requestPaths';
import { museApiFetcher } from '../../../utils/fetchers';
import { useAsyncFn } from '../../_shared/hooks';

export function useUpdatePlaylist(
  playlist: Playlist,
  onEndEdit: () => void
): {
  isUpdating: boolean;
  onUpdate: (data: Partial<DynamicPlaylist> | Partial<SearchedPlaylist>) => void;
} {
  const [isUpdating, setIsUpdating] = useState(false);
  const id = useMemo(() => (playlist.type === 'single' ? null : playlist.id), [playlist]);
  const pathOfUpdate = useMemo(() => {
    switch (playlist.type) {
      case 'single': {
        return null;
      }
      case 'dynamic': {
        return updateDynamicPlaylistPath(playlist.id);
      }
      case 'searched': {
        return updateSearchedPlaylistPath(playlist.id);
      }
    }
  }, [playlist]);
  const { mutate } = useSWRConfig();
  const [_state, handleUpdate] = useAsyncFn(
    async (data: Partial<DynamicPlaylist> | Partial<SearchedPlaylist>) => {
      if (!pathOfUpdate || !id) return;

      try {
        setIsUpdating(true);
        const { playlist } = await museApiFetcher<{ playlist: Playlist }>({ method: 'PUT', path: pathOfUpdate, data });
        await mutate(getPlaylistPath(id), playlist, true);
        setIsUpdating(false);
        onEndEdit();
      } catch (e) {
        // TODO: エラー処理
        console.error(e);
      }
    },
    [id, pathOfUpdate, onEndEdit, mutate]
  );

  return {
    isUpdating,
    onUpdate: handleUpdate,
  };
}
