import styled from '@emotion/styled';

export const BasicButton = styled.button`
  fill: currentColor;
  transition: filter 0.3s ease;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:disabled {
    cursor: default;
    filter: brightness(0.45);
  }
`;
