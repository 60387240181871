import styled from '@emotion/styled';
import { useCallback } from 'react';
import { smartphone } from '../../../../utils/mediaQueries';
import { useUploadMedia } from '../../hooks';
import { MediaUploadButton } from '../MediaUploadButton';

export type MediaUploadFormProps = {
  className?: string;
};

const Form = styled.form`
  width: 36px;
  height: 36px;

  ${smartphone`
    width: 42px;
    height: 42px;
  `}
`;

export const MediaUploadForm = ({ ...props }: MediaUploadFormProps) => {
  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => e.preventDefault(), []);
  const { onSubmit, onReject } = useUploadMedia();

  return (
    <Form {...props} onSubmit={handleSubmit}>
      <MediaUploadButton onAccept={onSubmit} onReject={onReject} />
    </Form>
  );
};
