import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { JobStatus } from '../../../../shared/types/job';
import { getUploadMediaJobPath } from '../../../constants/requestPaths';
import { museApiFetcher } from '../../../utils/fetchers';
import { useInterval } from '../../_shared/hooks';

export function useJob(jobId: number | null): JobStatus | null {
  const [jobFetchingTime, setJobFetchingTime] = useState(Date.now());
  const [jobStatus, setJobStatus] = useState<JobStatus | null>(null);
  const requestPath = useMemo(() => (jobId ? getUploadMediaJobPath(jobId) : null), [jobId]);
  const { data, error } = useSWR<{ status: JobStatus; progress: number }>(
    requestPath ? [requestPath, jobFetchingTime] : null,
    (path) => museApiFetcher({ path }),
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  useInterval(
    () => {
      setJobFetchingTime(Date.now());
    },
    3000,
    !jobId || !data || data.status === 'completed'
  );

  useEffect(() => {
    if (!data && !error) return;

    if (!data) {
      setJobStatus('failed');
      return;
    }

    setJobStatus(data.status);
  }, [data, error]);

  return jobStatus;
}
