import { ReactNode } from 'react';
import { useLoggedIn } from '../../hooks';
import { AuthenticationPage } from '../AuthenticationPage';

export type WithAuthenticationProps = {
  children: ReactNode;
};

export const WithAuthentication = ({ children }: WithAuthenticationProps) => {
  const isLoggedIn = useLoggedIn();

  if (isLoggedIn) return <>{children}</>;

  return <AuthenticationPage />;
};
