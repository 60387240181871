import { useEffect, useState } from 'react';

export function useWindowSize(): {
  width: number;
  height: number;
} {
  const [width, setWidth] = useState(document.body.clientWidth);
  const [height, setHeight] = useState(document.body.clientHeight);

  useEffect(() => {
    function handleResize() {
      setWidth(document.body.clientWidth);
      setHeight(document.body.clientHeight);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    width,
    height,
  };
}
