import { useCallback, useEffect, useState } from 'react';
import useSWRInfinite from 'swr/infinite';
import { PlaylistSummary, PlaylistSummaryType } from '../../../../shared/types/playlist';
import { getPlaylistsPath } from '../../../constants/requestPaths';

export function usePlaylists({ type }: { type?: PlaylistSummaryType }): {
  playlists: PlaylistSummary[];
  isFetching: boolean;
  isAllFetched: boolean;
  onLoadNext: () => void;
} {
  const [playlists, setPlaylists] = useState<PlaylistSummary[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isAllFetched, setIsAllFetched] = useState(false);
  const getKey = useCallback(
    (_pageInex: number, previousData: { playlists: PlaylistSummary[]; cursor: string | null } | null): string | null => {
      if (!previousData) return type ? `${getPlaylistsPath}?type=${type}` : getPlaylistsPath;

      if (!previousData.cursor) return null;

      return `${getPlaylistsPath}?cursor=${previousData.cursor}${type ? `&type=${type}` : ''}`;
    },
    [type]
  );
  const { data, error, size, setSize } = useSWRInfinite<{ playlists: PlaylistSummary[]; cursor: string | null }>(getKey);

  const handleLoadNext = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setSize(size + 1);
  }, [size]);

  useEffect(() => {
    if (!data && !error) {
      setIsFetching(true);
      return;
    }

    if (!data) {
      // TODO: エラー処理
      console.error('プレイリスト一覧データの取得エラー');
      return;
    }

    const nextPlaylist = data.map(({ playlists }) => playlists).flat();
    setPlaylists(nextPlaylist);
    setIsAllFetched(!data[data.length - 1].cursor);
    setIsFetching(false);
  }, [data, error]);

  return {
    playlists,
    isFetching,
    isAllFetched,
    onLoadNext: handleLoadNext,
  };
}
