import styled from '@emotion/styled';
import { ReactNode, useRef, useState } from 'react';
import { borderColor, mainColor } from '../../../../constants/colors';
import { scrollableY } from '../../../../utils/mixins';
import { useMeasure } from '../../../_shared/hooks';
import { Tab } from './Tab';

export type TabsProps = {
  tabs: string[];
  children: ReactNode;
  className?: string;
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${mainColor};
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-bottom: 2px solid ${borderColor};
`;

export const TabsItem = styled.div`
  ${scrollableY}
  width: 100%;
  height: 100%;
  max-height: 100%;
`;

const Body = styled.div<{ headerHeight: number; currentIndex: number }>`
  width: 100%;
  height: calc(100% - ${({ headerHeight }) => headerHeight}px);
  background-color: ${mainColor};

  & > ${TabsItem}:not(:nth-child(${({ currentIndex }) => currentIndex + 1})) {
    display: none;
  }
`;

export const Tabs = ({ tabs, children, ...props }: TabsProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { height } = useMeasure(ref);

  return (
    <Container {...props}>
      <HeaderWrapper ref={ref}>
        {tabs.map((tab, index) => (
          <Tab key={index} index={index} tabsCount={tabs.length} text={tab} selected={index === currentIndex} onClick={setCurrentIndex} />
        ))}
      </HeaderWrapper>
      <Body headerHeight={height} currentIndex={currentIndex}>
        {children}
      </Body>
    </Container>
  );
};
