export function shuffle<T>(arr: T[]): T[] {
  const next = [...arr];

  for (let i = next.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));

    [next[i], next[j]] = [next[j], next[i]];
  }

  return next;
}
