import styled from '@emotion/styled';
import { subColor } from '../../../../constants/colors';
import { ThreeDotsLoading } from '../Loadings';

export type LoadingPaneProps = {
  className?: string;
};

const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
`;

const Loading = styled(ThreeDotsLoading)`
  color: ${subColor};
  font-size: 14px;
`;

export const LoadingPane = ({ ...props }: LoadingPaneProps) => {
  return (
    <Container {...props}>
      <Loading />
    </Container>
  );
};
