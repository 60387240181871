import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import { SWRConfig } from 'swr';
import { AppTemplate } from './features/_shared/components/AppTemplate';
import { GlobalStyles } from './features/_shared/components/GlobalStyles';
import { LoadingScreen } from './features/_shared/components/LoadingScreen';
import { WithAuthentication } from './features/authentication/components/WithAuthentication';
import { MusicPlayerPage } from './features/music';
import { Routes } from './features/routes/Routes';
import { Toolbar } from './features/toolbar/components/Toolbar';
import { museApiFetcher } from './utils/fetchers';

ReactDOM.render(
  <SWRConfig
    value={{
      fetcher: (path) => museApiFetcher({ path }),
      revalidateOnFocus: false,
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      errorRetryCount: 3,
    }}
  >
    <RecoilRoot>
      <GlobalStyles />
      <Suspense fallback={<LoadingScreen />}>
        <WithAuthentication>
          <AppTemplate header={<Toolbar />} player={<MusicPlayerPage />}>
            <Routes />
          </AppTemplate>
        </WithAuthentication>
      </Suspense>
    </RecoilRoot>
  </SWRConfig>,
  document.getElementById('root')
);
