import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useCallback, useRef, useState } from 'react';
import { mainColor, subColor, subTextColor } from '../../../../../constants/colors';
import { useMeasure } from '../../../hooks';
import { IconArrowDropUp } from '../../Icons';

export type BasicSelectProps = {
  currentKey: string | null;
  placeholder: string;
  options: { key: string; value: string }[];
  onSelect: (key: string) => void;
  className?: string;
};

const borderSize = 2;

const Container = styled.div<{ optionsWidth: number; placeholderHeight: number }>`
  position: relative;
  width: ${({ optionsWidth }) => optionsWidth}px;
  height: ${({ placeholderHeight }) => placeholderHeight}px;

  & > div > p,
  & > div > button {
    font-size: 16px;
  }
`;

const Icon = styled(IconArrowDropUp)<{ isOpened: boolean }>`
  position: absolute;
  top: 50%;
  right: 2px;
  width: 32px;
  transform: translate(0, -50%) rotate(${({ isOpened }) => (isOpened ? 0 : 180)}deg);
  fill: ${subColor};
  user-select: none;
  transition: all 0.2s ease;
  z-index: 1;
`;

const Options = styled.div<{ isOpened: boolean; placeHolderHeight: number; optionsCount: number }>`
  position: absolute;
  padding: 0 16px 0 0;
  height: ${({ isOpened, placeHolderHeight, optionsCount }) => (isOpened ? placeHolderHeight * (optionsCount + 1) : placeHolderHeight)}px;
  background-color: #fff;
  border: ${borderSize}px solid ${subColor};
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 1;
`;

const Placeholder = styled.p`
  position: relative;
  top: -${borderSize}px;
  margin: 0;
  padding: 5px 16px;
  color: ${subTextColor};
  user-select: none;
  white-space: nowrap;
  border: ${borderSize}px solid transparent;
`;

const Option = styled.button<{ placeholderHeight: number; isOpened: boolean; selected: boolean; index: number }>`
  display: block;
  margin: 0;
  padding: 0 16px;
  width: 100%;
  color: ${mainColor};
  background-color: #fff;
  text-align: left;
  white-space: nowrap;
  outline: none;
  border: none;
  transition: all 0.2s ease;
  overflow: hidden;

  &:hover {
    background-color: ${({ isOpened }) => (isOpened ? 'rgba(0, 0, 0, .1)' : '#fff')};
  }

  ${({ selected, isOpened, placeholderHeight, index }) =>
    selected
      ? css`
          position: absolute;
          top: calc(${isOpened ? (index + 1) * placeholderHeight : 0}px - ${borderSize}px);
          height: ${placeholderHeight}px;
        `
      : css`
          position: absolute;
          top: calc(${(index + 1) * placeholderHeight}px - ${borderSize}px);
          transform: scaleY(${isOpened ? 1 : 0});
          height: ${isOpened ? placeholderHeight : 0}px;
        `}
`;

export const BasicSelect = ({ currentKey, placeholder, options, onSelect, ...props }: BasicSelectProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const placeholderRef = useRef<HTMLDivElement>(null);
  const { width } = useMeasure(containerRef);
  const { height } = useMeasure(placeholderRef);
  const handleClickContainer = useCallback(() => {
    if (isOpened) return;
    setIsOpened(true);
  }, [isOpened]);
  const handleClickPlaceholder = useCallback(() => {
    if (!isOpened) return;
    setIsOpened(false);
  }, [isOpened]);
  const handleSelect = useCallback(
    (key: string) => {
      onSelect(key);
      setIsOpened(false);
    },
    [onSelect]
  );

  return (
    <Container {...props} optionsWidth={width} placeholderHeight={height} onClick={handleClickContainer}>
      <Options ref={containerRef} isOpened={isOpened} placeHolderHeight={height} optionsCount={options.length}>
        <Placeholder ref={placeholderRef} onClick={handleClickPlaceholder}>
          {placeholder}
        </Placeholder>
        {options.map(({ key, value }, index) => (
          <Option key={key} index={index} selected={currentKey === key} placeholderHeight={height} isOpened={isOpened} onClick={() => handleSelect(key)}>
            {value}
          </Option>
        ))}
      </Options>
      <Icon isOpened={isOpened} />
    </Container>
  );
};
