export function stringifyTime(time: number, max: number): string {
  const t = Math.floor(time);
  const maxHH = Math.floor(Math.floor(max) / 60 / 60) % 60;

  const ss = t % 60;
  const mm = Math.floor(t / 60) % 60;
  const hh = Math.floor(t / 60 / 60) % 60;

  if (maxHH > 0) {
    return `${formatTime(hh)}:${formatTime(mm)}:${formatTime(ss)}`;
  }
  return `${formatTime(mm)}:${formatTime(ss)}`;
}

function formatTime(time: number): string {
  return `0${time}`.slice(-2);
}
