import { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import useSWR from 'swr';
import { PlaylistSummary, Playlist } from '../../../../shared/types/playlist';
import { getPlaylistPath } from '../../../constants/requestPaths';
import { playlistState } from '../../../recoil/playlist';

export function useSelectPlaylist(): {
  onSelect: (summary: PlaylistSummary) => void;
} {
  const [summary, setSummary] = useState<PlaylistSummary | null>(null);
  const [_playlist, setPlaylist] = useRecoilState(playlistState);
  const { data, error } = useSWR<{ playlist: Playlist }>(summary ? getPlaylistPath(summary.id) : null);

  const handleSelect = useCallback((summary: PlaylistSummary) => setSummary(summary), []);

  useEffect(() => {
    if (!data && !error) return;

    if (!data) {
      // TODO: エラー処理
      console.error('useSelectPlaylist error');
      return;
    }

    setPlaylist(data.playlist);
    setSummary(null);
  }, [data, error]);

  return {
    onSelect: handleSelect,
  };
}
