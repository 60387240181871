import styled from '@emotion/styled';
import { useEffect, useMemo, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { SinglePlaylist } from '../../../../../shared/types/playlist';
import { smartphoneSize } from '../../../../constants/sizes';
import { playlistState } from '../../../../recoil/playlist';
import { smartphone } from '../../../../utils/mediaQueries';
import { ThreeDotsLoading } from '../../../_shared/components/Loadings';
import { useIntersection, useWindowSize } from '../../../_shared/hooks';
import { PlaylistThumbnail } from '../PlaylistThumbnail';

export type MusicListProps = {
  playlists: SinglePlaylist[];
  isFetching: boolean;
  isAllFetched: boolean;
  onLoadNext: () => void;
  className?: string;
};

const Container = styled.div`
  width: 100%;
`;

const Playlists = styled.div<{ rows: number }>`
  display: grid;
  grid-template-columns: repeat(${({ rows }) => rows}, 1fr);
  grid-gap: 32px;
  width: 100%;

  ${smartphone`
    grid-gap: 16px;
  `}
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  font-size: 12px;

  & > * {
    color: #fff;
  }
`;

const Detector = styled.div`
  width: 100%;
  height: 100%;
`;

const LoadDetector = ({ onLoadNext }: { onLoadNext: () => void }) => {
  const ref = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(ref);
  useEffect(() => {
    if (!intersection?.isIntersecting) return;

    onLoadNext();
  }, [intersection]);

  return <Detector ref={ref} />;
};

export const MusicList = ({ playlists, isFetching, isAllFetched, onLoadNext, ...props }: MusicListProps) => {
  const { width } = useWindowSize();
  const rows = useMemo(() => (width <= smartphoneSize ? 2 : 5), [width]);

  const [_playlist, setPlaylist] = useRecoilState(playlistState);

  return (
    <Container {...props}>
      <Playlists rows={rows}>
        {playlists.map((playlist, index) => (
          <PlaylistThumbnail key={playlist.music.id} rowIndex={index % rows} playlist={playlist} onClick={setPlaylist} />
        ))}
      </Playlists>
      <LoadingWrapper>{isAllFetched ? null : isFetching ? <ThreeDotsLoading /> : <LoadDetector onLoadNext={onLoadNext} />}</LoadingWrapper>
    </Container>
  );
};
