import styled from '@emotion/styled';
import { HTMLAttributes, ReactNode } from 'react';

export type SlideUnderlineLabelProps = HTMLAttributes<HTMLDivElement> & {
  lineColor?: string;
  lineSize?: number;
  children: ReactNode;
  className?: string;
};

const Container = styled.div``;

const Text = styled.p<{ lineColor?: string; lineSize?: number }>`
  position: relative;
  display: inline-block;
  margin: 0;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: ${({ lineSize }) => lineSize ?? 1}px;
    background-color: ${({ lineColor }) => lineColor ?? 'currentColor'};
    transition: all 0.3s ease;
  }

  &:hover {
    &::before {
      width: 100%;
    }
  }
`;

export const SlideUnderlineLabel = ({ lineColor, lineSize, children, ...props }: SlideUnderlineLabelProps) => {
  return (
    <Container {...props}>
      <Text lineColor={lineColor} lineSize={lineSize}>
        {children}
      </Text>
    </Container>
  );
};
