import useSWRImmutable from 'swr/immutable';
import { getAuthPath } from '../../../constants/requestPaths';
import { FetcherError, museApiFetcher } from '../../../utils/fetchers';

export function useLoggedIn(): boolean {
  const { data: isLoggedIn } = useSWRImmutable<boolean, FetcherError>(
    getAuthPath,
    (path) =>
      museApiFetcher({
        path,
        handler: async (method, url, res) => {
          if (res.ok) {
            return true;
          }

          if (res.status === 401) {
            return false;
          }

          const data = await res.json();
          throw new FetcherError({
            method,
            url,
            statusCode: res.status,
            body: data,
          });
        },
      }),
    { suspense: true }
  );

  return isLoggedIn === true;
}
