import styled from '@emotion/styled';

export type FlexProps = React.HTMLAttributes<HTMLDivElement> & {
  inline?: boolean;
  justifyContent?: 'left' | 'center' | 'right';
  alignItems?: 'top' | 'middle' | 'bottom';
  direction?: 'column' | 'row';
  className?: string;
};

const justifyContentMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const alignItemMap = {
  top: 'flex-start',
  middle: 'center',
  bottom: 'flex-end',
};

const Div = styled.div<{
  inline?: boolean;
  justifyContent?: 'left' | 'center' | 'right';
  alignItems?: 'top' | 'middle' | 'bottom';
  direction?: 'column' | 'row';
}>`
  display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
  justify-content: ${(props) => justifyContentMap[props.justifyContent ?? 'left']};
  align-items: ${(props) => alignItemMap[props.alignItems ?? 'top']};
  flex-direction: ${(props) => props.direction ?? 'row'};
`;

export const Flex = (props: FlexProps) => <Div {...props} />;
