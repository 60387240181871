import styled from '@emotion/styled';
import { memo } from 'react';
import { accentOnColor, playerControlColor } from '../../../../../constants/colors';
import { BasicButton } from '../../../../_shared/components/Buttons';
import { IconShuffle } from '../../../../_shared/components/Icons';

export type ShuffleButtonProps = {
  on?: boolean;
  disabled?: boolean;
  onClick: () => void;
  className?: string;
};

const Button = styled(BasicButton)<{ on: boolean }>`
  color: ${({ on }) => (on ? accentOnColor : playerControlColor)};
`;

export const ShuffleButton = memo<ShuffleButtonProps>(({ on, disabled, onClick, ...props }) => {
  return (
    <Button on={on ?? false} disabled={disabled} onClick={onClick} {...props}>
      <IconShuffle />
    </Button>
  );
});
