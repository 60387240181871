import { ClassNames, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { memo, useCallback } from 'react';
import Modal from 'react-modal';
import { accentColor, boxShadowColor } from '../../../../constants/colors';
import { mediaMimeTypes } from '../../../../constants/media';
import { modalZIndex } from '../../../../constants/zIndex';
import { DropzoneInput } from '../../../_shared/components/DropzoneInput';
import { IconFileUpload } from '../../../_shared/components/Icons';

export type MediaUploadModalProps = {
  isOpened: boolean;
  onAccepted: (file: File) => void;
  onRejected: () => void;
  onClose: () => void;
  className?: string;
};

const iconAnimation = keyframes`
  0% {
    width: 120px;
    height: 120px;
    background-color: rgba(134, 112, 255, .1);
  }

  70% {
    width: 120px;
    height: 120px;
  }

  85% {
    width: 160px;
    height: 160px;
    background-color: rgba(134, 112, 255, .1);
  }

  90% {
    width: 170px;
    height: 170px;
    background-color: rgba(134, 112, 255, .05);
  }

  100% {
    width: 160px;
    height: 160px;
    background-color: rgba(134, 112, 255, 0);
  }
`;

const Dropzone = styled(DropzoneInput)`
  padding: 128px 8px 0;
  width: 100%;
  height: 100%;
`;

const IconWapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fff;
  user-select: none;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    background-color: rgba(134, 112, 255, 0.1);
    border-radius: 50%;
    animation: 2s ease-in ${iconAnimation} infinite;
    z-index: 0;
  }
`;

const IconBackground = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 50%;
`;

const IconInner = styled.div`
  padding: 18px;
  width: 100%;
  height: 100%;
  background-color: rgba(134, 112, 255, 0.2);
  border-radius: 50%;
  fill: ${accentColor};
`;

const Label = styled.p`
  margin: 48px 0 0;
  font-size: 16px;
  text-align: center;
  user-select: none;
`;

export const MediaUploadModal = memo<MediaUploadModalProps>(({ isOpened, onAccepted, onRejected, onClose, ...props }) => {
  const handleAccepted = useCallback((files: File[]) => onAccepted(files[0]), [onAccepted]);

  return (
    <ClassNames>
      {({ css }) => (
        <Modal
          isOpen={isOpened}
          overlayClassName={css`
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(255, 255, 255, 0.3);
            z-index: ${modalZIndex};
          `}
          className={css`
            position: absolute;
            top: 50%;
            left: 50%;
            width: 520px;
            height: 460px;
            background-color: #fff;
            border-radius: 6px;
            box-shadow: 0 0 8px ${boxShadowColor};
            transform: translate(-50%, -50%);
            outline: none;
          `}
          onRequestClose={onClose}
          {...props}
        >
          <Dropzone accept={mediaMimeTypes} onDropAccepted={handleAccepted} onDropRejected={onRejected}>
            <IconWapper>
              <IconBackground>
                <IconInner>
                  <IconFileUpload />
                </IconInner>
              </IconBackground>
            </IconWapper>
            <Label>アップロードする楽曲ファイルをドラッグ＆ドロップ</Label>
          </Dropzone>
        </Modal>
      )}
    </ClassNames>
  );
});
