import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useSWRConfig } from 'swr';
import { PlaylistSummaryType } from '../../../../shared/types/playlist';
import { createDynamicPlaylistPath, createSearchedPlaylistPath, getPlaylistsPath } from '../../../constants/requestPaths';
import { museApiFetcher } from '../../../utils/fetchers';
import { useAsyncFn } from '../../_shared/hooks';

export function useCreatePlaylist({ onClose }: { onClose: () => void }): {
  playlistType: PlaylistSummaryType;
  title: string;
  isSubmitting: boolean;
  isSubmittable: boolean;
  tags: string[];
  artists: string[];
  units: string[];
  onSelect: (playlistType: PlaylistSummaryType) => void;
  onChangeTitle: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeTags: (tags: string[]) => void;
  onChangeArtists: (artists: string[]) => void;
  onChangeUnits: (units: string[]) => void;
  onSubmit: () => void;
} {
  const { mutate } = useSWRConfig();
  const [playlistType, setPlaylistType] = useState<PlaylistSummaryType>('dynamic');
  const [title, setTitle] = useState('');
  const [tags, setTags] = useState<string[]>([]);
  const [artists, setArtists] = useState<string[]>([]);
  const [units, setUnits] = useState<string[]>([]);
  const isSubmittable = useMemo(() => title.length > 0, [title]);

  const handleSelect = useCallback((playlistType: PlaylistSummaryType) => {
    setPlaylistType(playlistType);
  }, []);

  const handleChangeTitle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  }, []);

  const [state, onSubmit] = useAsyncFn(async () => {
    switch (playlistType) {
      case 'dynamic': {
        await museApiFetcher({
          method: 'POST',
          path: createDynamicPlaylistPath,
          data: { title },
        });
        break;
      }
      case 'searched': {
        await museApiFetcher({
          method: 'POST',
          path: createSearchedPlaylistPath,
          data: { title, tags, artists, units },
        });
        break;
      }
    }
    await mutate(getPlaylistsPath, undefined, true);
    onClose();
  }, [playlistType, title, tags, artists, units, onClose]);

  return {
    playlistType,
    title,
    isSubmitting: state.status === 'loading',
    isSubmittable,
    tags,
    artists,
    units,
    onSelect: handleSelect,
    onChangeTitle: handleChangeTitle,
    onChangeTags: setTags,
    onChangeArtists: setArtists,
    onChangeUnits: setUnits,
    onSubmit: onSubmit,
  };
}
