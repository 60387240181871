import { DependencyList, useCallback, useEffect, useRef, useState } from 'react';

export type AsyncState<T, E extends Error> =
  | {
      status: 'pending';
      value?: T;
    }
  | {
      status: 'loading';
      value?: T;
    }
  | {
      status: 'success';
      value: T;
    }
  | {
      status: 'failure';
      error: E;
    };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PromiseFunction = (...args: any[]) => Promise<unknown>;

export type AsyncFnReturn<Fn extends PromiseFunction = PromiseFunction, E extends Error = Error> = [AsyncState<ReturnType<Fn>, E>, Fn];

export function useAsyncFn<Fn extends PromiseFunction, E extends Error = Error>(
  fn: Fn,
  deps: DependencyList,
  initialValue?: ReturnType<Fn>
): AsyncFnReturn<Fn, E> {
  const [asyncState, setAsyncState] = useState<AsyncState<ReturnType<Fn>, E>>({ status: 'pending', value: initialValue });
  const lastFnId = useRef(0);
  const isMounted = useRef(false);

  const callFn = useCallback((...args: Parameters<Fn>): ReturnType<Fn> => {
    const fnId = lastFnId.current + 1;
    lastFnId.current = fnId;

    setAsyncState((prev) => {
      if (prev.status === 'failure') return { status: 'loading', value: initialValue };

      return { status: 'loading', value: prev.value };
    });

    return fn(...args).then(
      (value) => {
        if (isMounted.current && lastFnId.current === fnId) {
          setAsyncState({ status: 'success', value: value as ReturnType<Fn> });
        }

        return value;
      },
      (error) => {
        if (isMounted.current && lastFnId.current === fnId) {
          setAsyncState({ status: 'failure', error });
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return error;
      }
    ) as ReturnType<Fn>;
  }, deps);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return [asyncState, callFn as unknown as Fn];
}
