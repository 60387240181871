import { useMemo, useState } from 'react';
import { useSWRConfig } from 'swr';
import { MusicMeta } from '../../../../shared/types/media';
import { getMusicPath, updateMusicPath } from '../../../constants/requestPaths';
import { museApiFetcher } from '../../../utils/fetchers';
import { useAsyncFn } from '../../_shared/hooks';

export function useUpdateMusic(
  id: string | null,
  onEndEdit: () => void
): {
  isUpdating: boolean;
  onUpdate: (data: Partial<MusicMeta>) => void;
} {
  const [isUpdating, setIsUpdating] = useState(false);
  const pathOfFetch = useMemo(() => (id ? getMusicPath(id) : null), [id]);
  const pathOfUpdate = useMemo(() => (id ? updateMusicPath(id) : null), [id]);
  const { mutate } = useSWRConfig();
  const [_state, handleUpdate] = useAsyncFn(
    async (data: Partial<MusicMeta>) => {
      if (!pathOfFetch || !pathOfUpdate) return;

      try {
        setIsUpdating(true);
        const { music } = await museApiFetcher<{ music: MusicMeta }>({ method: 'PUT', path: pathOfUpdate, data });
        await mutate(pathOfFetch, music, true);
        setIsUpdating(false);
        onEndEdit();
      } catch (e) {
        // TODO: エラー処理
        console.error(e);
      }
    },
    [pathOfFetch, pathOfUpdate, onEndEdit, mutate]
  );

  return {
    isUpdating,
    onUpdate: handleUpdate,
  };
}
