import styled from '@emotion/styled';
import { memo } from 'react';
import { playerControlColor } from '../../../../../constants/colors';
import { BasicButton } from '../../../../_shared/components/Buttons';
import { IconArrowDropUp } from '../../../../_shared/components/Icons';

export type PanelCollapseButtonProps = {
  isCollapsed: boolean;
  disabled?: boolean;
  onClick: () => void;
  className?: string;
};

const Button = styled(BasicButton)<{ isCollapsed: boolean }>`
  color: ${playerControlColor};
  transition: transform 0.3s ease;
  transform: rotate(${({ isCollapsed }) => (isCollapsed ? 0 : 180)}deg);
`;

export const PanelCollapseButton = memo<PanelCollapseButtonProps>(({ isCollapsed, disabled, onClick, ...props }) => {
  return (
    <Button isCollapsed={isCollapsed} disabled={disabled} onClick={onClick} {...props}>
      <IconArrowDropUp />
    </Button>
  );
});
