import { DynamicPlaylist, SearchedPlaylist } from '../../../../../shared/types/playlist';
import { EditDynamicPlaylistMetaPane } from './EditDynamicPlaylistMetaPane';
import { EditSearchedPlaylistMetaPane } from './EditSearchedPlaylistMetaPane';

export type EditPlaylistMetaPaneProps = {
  playlist: DynamicPlaylist | SearchedPlaylist;
  isUpdating: boolean;
  onEndEdit: () => void;
  onSubmit: (data: Partial<DynamicPlaylist | SearchedPlaylist>) => void;
  className?: string;
};

export const EditPlaylistMetaPane = ({ playlist, isUpdating, onEndEdit, onSubmit, ...props }: EditPlaylistMetaPaneProps) => {
  switch (playlist.type) {
    case 'dynamic': {
      return <EditDynamicPlaylistMetaPane playlist={playlist} isUpdating={isUpdating} onEndEdit={onEndEdit} onSubmit={onSubmit} {...props} />;
    }
    case 'searched': {
      return <EditSearchedPlaylistMetaPane playlist={playlist} isUpdating={isUpdating} onEndEdit={onEndEdit} onSubmit={onSubmit} {...props} />;
    }
  }
};
